<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <g clip-path="url(#clip0_480_1779)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.035 13.596C10.7853 14.5856 9.21557 15.0815 7.62419 14.9893C6.03281 14.8972
        4.53076 14.2236 3.4036 13.0964C2.27644 11.9692 1.60276 10.4672 1.51065 8.87581C1.41854 7.28443 1.91441 5.71468 2.904 4.465L12.035
        13.596ZM13.096 12.536L3.965 3.403C5.21468 2.41341 6.78443 1.91754 8.37581 2.00965C9.96719 2.10176 11.4692 2.77544 12.5964 3.9026C13
        .7236 5.02976 14.3972 6.53181 14.4893 8.12319C14.5815 9.71457 14.0856 11.2843 13.096 12.534V12.536ZM16 8.5C16 10.6217 15.1571 12.6566
        13.6569 14.1569C12.1566 15.6571 10.1217 16.5 8 16.5C5.87827 16.5 3.84344 15.6571 2.34315 14.1569C0.842855 12.6566 0 10.6217 0 8.5C0
        6.37827 0.842855 4.34344 2.34315 2.84315C3.84344 1.34285 5.87827 0.5 8 0.5C10.1217 0.5 12.1566 1.34285 13.6569 2.84315C15.1571 4.34344 16 6.37827 16 8.5Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_480_1779">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
    </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.component('soi-icon-cancel', {

});
</script>
