<template>
    <v-card outlined class="mt-2 mb-2">
        <v-card-text>
            <v-card-title>Regras de Obrigatoriedade De Campos</v-card-title>
            <v-row>
                <v-col cols="12" xs="12">
                    <v-card outlined>
                        <v-card-title>Habilitar/Desabilitar Campos Obrigatórios</v-card-title>
                        <v-card-text>
                            <v-row>
                                <soi-switch :name="'Habilitar Obrigatoriedade CPF'"
                                    :label="pacoteAtivo.habilitarCpf ? 'CPF obrigatório no cadastro' : 'CPF não obrigatório no cadastro'"
                                    v-model="pacoteAtivo.habilitarCpf" :disabled="disabled">
                                </soi-switch>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import Vue from 'vue';
import ModuloConfiguracaoCampos from '@/models/objetosDeValor/ModuloConfiguracaoCampos';
import { MensagemMixin } from '@/helpers/MensagemHelper';

export default Vue.component('soi-modulos-configuracao-campos', {
  props: {
    value: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      pacoteAtivo: new ModuloConfiguracaoCampos(),
      carregando: true,
    };
  },
  watch: {
    value() {
      this.pacoteAtivo = this.value;
    },
    pacoteAtivo: {
      handler() {
        this.$emit('input', this.pacoteAtivo);
      },
      deep: true,
    },
    'pacoteAtivo.habilitarCpf': {
      handler(valor) {
        if (!this.carregando) {
          this.ativarOuDesativar(valor);
        }
      },
    },
  },
  mounted() {
    this.carregando = false;
  },
  methods: {
    async ativarOuDesativar() {
      if (this.pacoteAtivo.habilitarCpf) {
        MensagemMixin(
          3000,
          'success',
          'CPF habilitado como obrigatório no cadastro do paciente.',
        );
      } else {
        MensagemMixin(
          3000,
          'success',
          'CPF desabilitado no cadastro do paciente.',
        );
      }
    },
  },
});
</script>
