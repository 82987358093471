<template>
  <validation-provider v-slot="{ errors }" :name="name" :rules="rules">
      <v-text-field v-if="mask !== ''" outlined dense v-model="input" :id="name" :name="name" :label="label"
          :hint="hint" :prepend-icon="prependIcon" :autocomplete="autocomplete" :disabled="disabled" v-mask="mask"
          :type="type" :error-messages="errors" :placeholder="placeholder"
          :persistent-placeholder="persistentPlaceholder" :readonly="readonly" @input="$emit('input', input)"
          color="success">
          <template v-slot:append v-if="search">
              <div class="icon-search">
                  <v-icon color="white" size="14">
                      $search
                  </v-icon>
              </div>
          </template>
      </v-text-field>
      <v-text-field v-else outlined dense v-model="input" :id="name" :name="name" :label="label" :disabled="disabled"
          :append-icon="type === 'password' ? showSenha ? 'mdi-eye' : 'mdi-eye-off' : ''"
          :type="type === 'password' ? showSenha ? 'text' : 'password' : type" :hint="hint" :prepend-icon="prependIcon"
          :autocomplete="autocomplete" :error-messages="errors" :placeholder="placeholder"
          :persistent-placeholder="persistentPlaceholder" @input="$emit('input', input)" :readonly="readonly"
          @click:append="showSenha = !showSenha" color="success">
          <template v-slot:append>
              <div class="icon-search" v-if="search">
                  <v-icon color="white" size="14">
                      $search
                  </v-icon>
              </div>
          </template>
      </v-text-field>
  </validation-provider>
</template>


<script lang="ts">
import Vue from 'vue';

export default Vue.component('soi-input', {
  components: {
  },
  props: {
    search: {
      type: Boolean,
      default: false,
      required: false,
    },
    name: {
      type: String,
      default: '',
      required: false,
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    mask: {
      default: '',
    },
    placeholder: {
      type: String,
      default: '',

    },
    rules: {
      type: Object,
      default() {
        return {};
      },
      required: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    hint: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: '',
    },

    situation: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      input: this.value,
      showSenha: false,
    };
  },
  watch: {
    value() {
      this.input = this.value;
    },
  },
  methods: {

  },
});
</script>

<style>
.icon-search {
  background-color: #10A860;
  border-radius: 4px;
  padding: 6px;
  gap: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
}
</style>
