import Clinica from '../clinica/Clinica';

export default class Tenant {
    public id: number;

    public nome: string;

    public nomeDeExibicao : string;

    public ativo : boolean;

    public clinicaId: number;

    public clinica: Clinica;

    constructor() {
      this.id = 0;
      this.nome = '';
      this.nomeDeExibicao = '';
      this.ativo = true;
      this.clinicaId = 0;
      this.clinica = new Clinica();
    }
}
