<template>
    <v-app-bar app absolute elevation="0">
        <v-toolbar-title class="titulo">{{ titulo }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <div class="info-paciente" v-if="$route.name === 'atendimentoForm'">
            Paciente:
            <span>{{ nomePaciente }}</span>
        </div>
        <v-spacer></v-spacer> -->
        <!-- <v-btn color="primary" class="btn-paciente" title="Informações do Paciente" @click=""
            v-if="$route.name === 'atendimentoForm'">
            <v-icon class="icon-btn" size="24" color="#fff">$infoOutline</v-icon>
            Info Paciente
        </v-btn> -->
        <div class="menu-user">
            <v-menu v-model="menuOpen" offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <div class="user-container" v-bind="attrs" v-on="on">
                        <v-avatar size="48" class="white--text text-h5 success darken-2">
                            <img v-if="user && user.profilePicture" :src="'https://medsystemcloud-new.s3.us-west-2.amazonaws.com/' +
                                user.profilePicture" />
                            <img v-else-if="user && user.role === 'Host'" src="../assets/images/logo.jpeg" />
                            <span v-else>
                                {{
                                    formatarIniciaisNomeSobrenome(user.name, user.sobrenome)
                                }}
                            </span>
                        </v-avatar>
                        <div class="user-info">
                            <span>{{ user.name }}</span>
                            <h5>{{ user.role === 'Admin' ? 'Administrador Master' : user.role }}</h5>
                        </div>
                        <v-spacer></v-spacer>
                        <v-icon>{{ menuOpen ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                    </div>
                </template>
                <v-list class="menu-list">
                    <v-list-item v-if="user && user.role === 'Admin'" class="menu-item"
                        @click="$router.push({ name: 'clinicasFormUpdate' })">
                        <v-icon>$clinical</v-icon>
                        <span>Clínica</span>
                    </v-list-item>
                    <v-list-item v-if="user.role === 'Host'" class="menu-item"
                        @click="$router.push({ name: 'clinicasFormUpdate' })">
                        <v-icon>$clinical</v-icon>
                        <span>Criar Clínica</span>
                    </v-list-item>
                    <v-list-item v-else @click="$router.push({ name: 'perfilDoUsuario' })">
                        <v-icon>$profile</v-icon>
                        <span>Perfil</span>
                    </v-list-item>
                    <v-list-item @click="alterarTemaDoSistema()">
                        <v-icon>{{ modoNoturno ? '$lightMode' : '$darkMode' }}</v-icon>
                        <span> {{ modoNoturno ? "Modo Claro" : "Modo Escuro" }}</span>
                    </v-list-item>
                    <v-list-item @click="sair()">
                        <v-icon>$logout</v-icon>
                        <span>Sair</span>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </v-app-bar>
</template>

<script lang="ts">
import Vue from 'vue';
import NavigationHelper from '../helpers/NavigationHelper';
import {
  getModoNoturno,
  removeToken,
  setModoNoturno,
  decode,
  getToken,
} from '../utils/Token';
import { AlertaPerguntaSimOuNaoBooleano } from '../helpers/MensagemHelper';
import { UsuarioService } from '../services/UsuarioService';

export default Vue.extend({
  name: 'soi-header',
  components: {
  },
  props: {
    value: {
      type: Boolean,
    },
    titulo: {
      type: String,
      required: true,
    },
    nomePaciente: {
      type: String,
      required: false,
      default: 'Teste de Oliveira Santos',
    },
  },
  watch: {
    user() {
      this.modoNoturno = getModoNoturno();
    },

    '$route.name': {
      handler(name) {
        this.visualizar = !!(name !== 'login'
                        && name !== 'recuperarSenha'
                        && name !== 'confirmaEmail'
                        && name !== 'confirmaAgendamento');
      },
    },
  },
  data() {
    return {
      menuOpen: false,
      user: {
        name: '',
        sobrenome: '',
        profilePicture: '',
        role: '',
      },
      modoNoturno: getModoNoturno(),
    };
  },

  mounted() {
    this.atualizarInformacoesUsuario();
    this.$root.$on('atualizar-usuario', this.atualizarInformacoesUsuario);
    this.$vuetify.theme.dark = this.modoNoturno;
  },

  methods: {
    atualizarInformacoesUsuario() {
      const token = getToken();

      if (token) {
        this.user = decode(token);
      }
    },

    alterarTemaDoSistema() {
      const usuarioService = new UsuarioService();
      usuarioService.MudarTema().then(
        (res) => {
          this.modoNoturno = res.data;
          this.$vuetify.theme.dark = this.modoNoturno;
          setModoNoturno(this.modoNoturno);
        },
        (err) => {
          // eslint-disable-next-line no-alert
          alert('Não foi possível realizar a operação');
        },
      );
    },

    async sair() {
      if (await AlertaPerguntaSimOuNaoBooleano('Deseja sair do sistema?')) {
        removeToken();
        this.$vuetify.theme.dark = false;
        NavigationHelper.navigate('login');
      }
    },

    redirecionarParaClinica() {
      if (this.user.role === 'Admin' || this.user.role === 'Host') {
        NavigationHelper.navigate('clinicasFormUpdate');
      }
    },

    redirecionarParaUsuario() {
      NavigationHelper.navigate('perfilDoUsuario');
    },

    formatarIniciaisNomeSobrenome(nome: string, sobrenome: string) {
      if (nome && sobrenome) {
        const iniciais = nome.charAt(0).toUpperCase() + sobrenome.charAt(0).toUpperCase();
        return iniciais;
      }
      if (nome) {
        return nome.charAt(0).toUpperCase();
      }

      return 'H';
    },
  },
});
</script>

<style>
.titulo {
    margin-left: 3.625rem;
    margin-top: 1rem;
    color: #10a860;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: justify;
}

.menu-user {
    margin-left: 1.875rem;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    padding: 0.75rem;
    cursor: pointer;
}

.user-container {
    display: flex;
    align-items: center;
}

.menu-list {
    padding: 1rem;
}

.menu-item {
    padding-left: 1rem;
}

.v-icon {
    margin-right: 1rem;
}

.user-info {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}

.user-info span {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375rem;
    text-align: justify;
    color: #4E4E4E;
}

.user-info h5 {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #A4A8AB;
}

.v-list-item span {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #4E4E4E;
}

.v-list-item .v-icon {
    color: #4E4E4E;
}

.v-menu__content {
    margin-top: 1rem;
}

.info-paciente {
    margin-top: 1rem;
    margin-right: 1rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: justify;
    gap: 0.5rem;
    color: #4E4E4E;
}

.info-paciente span {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: justify;
}

.btn-paciente {
    background-color: #10A860;
    border-radius: 4px;
    padding: 0.5rem 1rem 0.5rem 1rem !important;
    gap: 0.5rem;
    color: #fff;
    margin-top: 1rem;
}

.btn-paciente .v-btn__content {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.32px;
    text-align: justify;
    text-transform: capitalize;
}

.icon-btn {
    margin-right: 0.5rem !important;
}
</style>
