<template>
    <v-dialog
        v-model="modalAberto"
        transition="dialog-bottom-transition"
        max-width="1200"
    >
        <v-card class="mx-auto" :loading="carregando ? 'success' : null" :disabled="carregando">
            <v-toolbar flat outlined>
                <v-toolbar-title><v-icon left size="x-large" color="success">mdi-tray-full</v-icon>Consultar Agendamentos</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="fecharModal()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-data-table
                    :headers="colunas"
                    :loading="carregando ? 'success' : null"
                    loading-text="Buscando os agendamentos..."
                    no-data-text="Nenhum agendamento encontrado!"
                    :items-per-page="itens"
                    :items="listaAgendamentos"
                    :page.sync="paginaAtual"
                    hide-default-footer
                    single-select
                >
                    <template v-slot:top>
                        <v-card class="elevation-0">
                            <v-card-text>
                                <v-row cols="12">
                                    <v-col cols="12" sm="12" md="4">
                                        <v-radio-group v-model="tipoFiltro" row>
                                            <v-radio
                                                color="success"
                                                class="radioFlex"
                                                v-for="itens in opcoesFiltro"
                                                :label="itens.name"
                                                :value="itens.key"
                                                :key="itens.key"
                                            >
                                            </v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field
                                            :type="tipoFiltroComputado"
                                            outlined
                                            dense
                                            color="success"
                                            v-model="valorFiltro"
                                            @input="listar()"
                                            append-icon="mdi-magnify"
                                            :label="labelFiltro"
                                            class="mt-4"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="2">
                                        <v-select
                                            outlined
                                            dense
                                            color="success"
                                            item-color="success"
                                            v-model="itens"
                                            label="Qtde.Itens"
                                            :items="quantidadeItens"
                                            @input="listar()"
                                            class="mt-4"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-slot:item.descricaoPaciente="{ item }">
                    {{ item.agendamentoConsulta ? item.agendamentoConsulta.paciente.nomeCompleto : item.agendamentoCompromisso.descricao }}
                    </template>
                    <template v-slot:item.situacaoAgendamento="{ item }">
                        <v-chip :color="obterSituacaoAgendamento(item.situacaoAgendamento).color" small label>
                            <v-icon x-small left>{{ obterSituacaoAgendamento(item.situacaoAgendamento).icon }}</v-icon>
                            {{ obterSituacaoAgendamento(item.situacaoAgendamento).label }}
                        </v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                v-show="permiteCadastroAgendamentos"
                                class="mr-2"
                                x-small
                                icon
                                color="warning"
                                @click="editarAgendamento(item)"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-square-edit-outline</v-icon>
                            </v-btn>
                            </template>
                            <span>Editar Agendamento</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                <v-pagination
                    color="success"
                    @input="listar()"
                    v-model="paginaAtual"
                    :length="totalPaginas"
                    class="mt-2 pt-2"
                >
                </v-pagination>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import DateHelper from '../../helpers/DateHelper';
import { AlertaSimplesErro } from '../../helpers/MensagemHelper';
import Agendamento from '../../models/agendamento/Agendamento';
import { AgendamentoService } from '../../services/AgendamentoService';

export default Vue.component('soi-buscar-agendamento', {
  data() {
    return {
      modalAberto: false,
      carregando: false,
      permiteCadastroAgendamentos: false,
      agendaId: 0,
      linhaSelecionada: [],
      listaAgendamentos: [] as Agendamento[],
      agendamentoService: new AgendamentoService(),
      tipoFiltro: 'idOuNome',
      tipoFiltroNumber: 0,
      valorFiltro: '',
      opcoesFiltro: [
        { name: 'Paciente', key: 'idOuNome', type: 'string' },
        { name: 'Compromisso', key: 'descricao', type: 'string' },
        {
          name: 'Data',
          key: 'dataAgendamento',
          type: 'date',
        },
      ],
      totalPaginas: 0,
      paginaAtual: 1,
      itens: 0,
      quantidadeItens: [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
      ],
      colunas: [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          filterable: true,
          sortable: true,
        },
        {
          text: 'Tipo de Agendamento',
          value: 'tipoAgendamento',
          filterable: true,
          sortable: true,
        },
        {
          text: 'Paciente ou Compromisso',
          value: 'descricaoPaciente',
          filterable: true,
          sortable: true,
        },
        {
          text: 'Data Agendada',
          value: 'dataAgendamento',
          filterable: true,
          sortable: true,
        },
        {
          text: 'Hora Agendada',
          value: 'horaAgendamento',
        },
        {
          text: 'Situação',
          value: 'situacaoAgendamento',
        },
        {
          text: 'Ações',
          value: 'actions',
        },
      ],
      selectedId: -1,
      opcoesSituacaoAgendamento: [
        {
          value: 0,
          text: 'Marcado',
          label: 'Marcado',
          icon: 'mdi-calendar-check',
          color: '#84b6f4',
        },
        {
          value: 1,
          text: 'EmEspera',
          label: 'Em Espera',
          icon: 'mdi-clock-alert-outline',
          color: '#77dd77',
        },
        {
          value: 2,
          text: 'EmAndamento',
          label: 'Em Andamento',
          icon: 'mdi-progress-check',
          color: '#ffda9e',
        },
        {
          value: 3,
          text: 'Faltou',
          label: 'Faltou',
          icon: 'mdi-close-octagon-outline',
          color: '#ff6961',
        },
        {
          value: 4,
          text: 'Finalizado',
          label: 'Finalizado',
          icon: 'mdi-check-circle-outline',
          color: '#c5c6c8',
        },
        {
          value: 5,
          text: 'Cancelado',
          label: 'Cancelado',
          icon: 'mdi-cancel',
          color: '#ffe180',
        },
      ],
    };
  },
  computed: {
    tipoFiltroComputado() {
      return this.opcoesFiltro.find((x) => x.key === this.tipoFiltro)
        ?.type;
    },
    descricaoPaciente() {
      return this.listaAgendamentos.map((agendamento) => ({
        agendamentoConsulta: agendamento.agendamentoConsulta ? agendamento.agendamentoConsulta.nomeCompleto : '',
        agendamentoCompromisso: agendamento.agendamentoCompromisso ? agendamento.agendamentoCompromisso.descricao : '',
      }));
    },
    labelFiltro() {
      const itemSelecionado = this.opcoesFiltro.find((item) => item.key === this.tipoFiltro);

      if (itemSelecionado) {
        return `Digite um ${itemSelecionado.name} para pesquisar...`;
      }

      return 'Digite o que deseja pesquisar...';
    },
  },
  methods: {
    obterSituacaoAgendamento(situacao) {
      const opcao = this.opcoesSituacaoAgendamento.find((op) => op.text === situacao);
      return {
        color: opcao.color,
        icon: opcao.icon,
        label: opcao.label,
      };
    },

    editarAgendamento(agendamento) {
      this.fecharModal();
      this.$emit('editarAgendamento', agendamento);
    },

    abrirModal(agendaId: number, permiteCadastroAgendamentos: boolean) {
      this.permiteCadastroAgendamentos = permiteCadastroAgendamentos;
      this.agendaId = agendaId;
      this.modalAberto = true;
      this.itens = 5;
      this.listar();
    },

    fecharModal() {
      this.modalAberto = false;
      this.limpaPesquisa();
    },

    limpaPesquisa() {
      this.listaAgendamentos = [];
      this.paginaAtual = 1;
      this.valorFiltro = '';
    },

    pesquisa() {
      this.paginaAtual = 1;
      this.listar();
    },

    async listar() {
      this.carregando = true;
      await this.agendamentoService
        .BuscarAgendamentos(
          this.agendaId,
          this.tipoFiltro === 'dataAgendamento'
            ? this.valorFiltro
            : '',
          this.tipoFiltro === 'idOuNome' ? this.valorFiltro : '',
          this.tipoFiltro === 'descricao' ? this.valorFiltro : '',
          this.itens,
          this.paginaAtual,
        )
        .then(
          (res) => {
            this.listaAgendamentos = res.data.itens;
            this.totalPaginas = res.data.totalPaginas;
            this.paginaAtual = res.data.paginaAtual;
            this.listaAgendamentos.forEach((agendamento) => {
              // eslint-disable-next-line no-param-reassign
              agendamento.dataAgendamento = DateHelper.formataParaDiaMesAno(
                agendamento.dataAgendamento,
              );
            });
          },
          (err) => {
            AlertaSimplesErro('Aviso:', err);
          },
        );
      this.carregando = false;
    },
  },
});
</script>

<style scoped>
a {
    text-decoration: none;
}

.radioFlex {
    line-height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
