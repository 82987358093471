<template>
  <div class="text-center">
    <v-card flat class="mt-2 mb-2">
      <v-row>
        <v-col cols="11">
          <v-card-title>{{ titulo }}</v-card-title>
        </v-col>
        <v-col cols="12" md="12" class="d-flex justify-end">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mb-1" color="success" dark @click="abrirModalNovoConvenio" v-if="!visualizar"
                :disabled="disabled" v-bind="attrs" v-on="on">
                <v-icon rigth class="mr-2">mdi-account-plus</v-icon>
                Novo Convênio
              </v-btn>
            </template>
            <span>Novo Convênio</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-card-text>
        <v-row>
          <v-col cols="12" xs="12">
            <!-- <h3>{{ titulo }}</h3> -->
            <v-row>
              <v-col cols="12" md="6" sm="12" xs="12">
                <soi-select :label="obterTituloConvenio" :items="listaConvenios" @input="mudaListaPlanos"
                  v-model="pacienteConvenio.convenioId" :disabled="disabled"></soi-select>
              </v-col>
              <v-col cols="12" md="6" sm="12" xs="12">
                <soi-select :label="'Plano'" :items="listaPlanos" v-model="pacienteConvenio.planoId"
                  :disabled="disabled || desabilitaPlano"></soi-select>
              </v-col>
              <v-col cols="12" md="6" sm="12" xs="12">
                <soi-input :name="'Número de Registro'" :label="'Número de Registro'"
                  v-model="pacienteConvenio.numeroRegistro" :placeholder="'Número de Registro'"
                  :disabled="disabled || desabilitaPlano"></soi-input>
              </v-col>
              <v-col cols="12" md="6" sm="12" xs="12">
                <soi-input :name="'Data de Vencimento'" :label="'Data de Vencimento'"
                  :disabled="disabled || desabilitaPlano" v-model="pacienteConvenio.vencimento" type="date"></soi-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <soi-adicionar-novo-convenio ref="modalAdicionarNovoConvenio"
      @convenioCadastrado="atualizarInfoConvenios"></soi-adicionar-novo-convenio>
  </div>
</template>

<script>
import Vue from 'vue';
import PacientesConvenio from '../../models/convenio/PacientesConvenio';
import { ConvenioService } from '../../services/ConveioService';
import { PlanoService } from '../../services/PlanoService';

export default Vue.component('soi-dados-padrao-convenio', {

  props: {
    visualizar: {
      type: Boolean,
      default: false,
    },
    value: {
      type: PacientesConvenio,
      default: () => new PacientesConvenio(),
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    titulo: {
      type: String,
      required: false,
      default: 'Convênio',
    },
  },

  data() {
    return {
      pacienteConvenio: new PacientesConvenio(),
      convenioService: new ConvenioService(),
      planoService: new PlanoService(),
      idPaciente: parseInt(this.$route.params.id, 10),
      listaConvenios: [],
      listaPlanos: [],
      desabilitaPlano: true,
    };
  },

  methods: {
    async mudaListaPlanos() {
      this.pacienteConvenio.planoId = undefined;
      this.preenchePlanos();
    },

    async preenchePlanos() {
      await this.planoService
        .getAllSelect(this.pacienteConvenio.convenioId)
        .then((res) => {
          this.listaPlanos = res.data;
        });
      if (
        this.listaPlanos.length === 0
        || this.pacienteConvenio.convenioId === undefined
      ) {
        this.desabilitaPlano = true;
        this.listaPlanos = [];
        this.pacienteConvenio.numeroRegistro = '';
        this.pacienteConvenio.vencimento = '0001-01-01T00:00:00';
      } else {
        this.desabilitaPlano = false;
      }
    },

    async abrirModalNovoConvenio() {
      await this.$refs.modalAdicionarNovoConvenio.abrirModalAdicionarConvenio();
    },

    async atualizarInfoConvenios() {
      await this.convenioService.getAllSelect().then((res) => {
        this.listaConvenios = res.data;
      });
    },
  },

  computed: {
    obterTituloConvenio() {
      return this.titulo;
    },
  },

  watch: {
    value() {
      this.pacienteConvenio = this.value;
      if (this.pacienteConvenio.convenioId !== 0) {
        this.preenchePlanos();
      }
    },

    'pacienteConvenio.convenioId': function () {
      this.$emit('input', this.pacienteConvenio);
    },
  },

  mounted() {
    this.$nextTick(async function () {
      await this.convenioService.getAllSelect().then((res) => {
        this.listaConvenios = res.data;
      });
    });
  },

});
</script>
<style>
h3 {
  display: flex;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.btn-novo-convenio {
  width: 50px;
  height: 50px
}
</style>
