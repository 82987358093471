import { render, staticRenderFns } from "./AvatarInput.vue?vue&type=template&id=66bf5e88&scoped=true"
import script from "./AvatarInput.vue?vue&type=script&lang=ts"
export * from "./AvatarInput.vue?vue&type=script&lang=ts"
import style0 from "./AvatarInput.vue?vue&type=style&index=0&id=66bf5e88&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66bf5e88",
  null
  
)

export default component.exports