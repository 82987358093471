import { TipoPessoas } from '../enums/EnumTipoPessoa';
import ModuloConfiguracaoCampos from '../objetosDeValor/ModuloConfiguracaoCampos';
import Contato from '../objetosDeValor/Contato';
import Endereco from '../objetosDeValor/Endereco';
import ModulosComplementares from '../objetosDeValor/ModulosComplementares';
import Tenant from '../tenant/Tenant';
import ClinicaConfirmarAgendamento from './ClinicaConfirmarAgendamento';

export default class Clinica {
    public id: number;

    public tenant?: Tenant;

    public nomeDominio: string;

    public razaoSocial: string;

    public nomeMedico: string;

    public usuarioMedico: string;

    public dataNascimento: string;

    public email: string;

    public tipoPessoa?: TipoPessoas;

    public documento: string;

    public usoLiberado: boolean;

    public diaDeVencimento: number;

    public carencia: number;

    public medicosPermitidos: number;

    public endereco!: Endereco;

    public contato!: Contato;

    public file?: File;

    public foto: string;

    public modulosComplementares: ModulosComplementares;

    public clinicaConfirmarAgendamento: ClinicaConfirmarAgendamento;

    public moduloConfiguracaoCampos?: ModuloConfiguracaoCampos;

    public tenantId: number;

    constructor() {
      this.id = 0;
      this.nomeDominio = '';
      this.razaoSocial = '';
      this.nomeMedico = '';
      this.usuarioMedico = '';
      this.dataNascimento = '';
      this.email = '';
      this.documento = '';
      this.usoLiberado = true;
      this.diaDeVencimento = 0;
      this.carencia = 0;
      this.medicosPermitidos = 0;
      this.endereco = new Endereco();
      this.contato = new Contato();
      this.modulosComplementares = new ModulosComplementares();
      this.clinicaConfirmarAgendamento = new ClinicaConfirmarAgendamento();
      this.moduloConfiguracaoCampos = new ModuloConfiguracaoCampos();
      this.foto = '';
      this.tenantId = 0;
    }
}
