import { Especialidade } from '../enums/EnumEspecialidade';
import { TipoPessoas } from '../enums/EnumTipoPessoa';
import Endereco from './Endereco';

export default class DadosMedico {
    public crm:string;

    public especialidade?: Especialidade;

    public endereco!: Endereco;

    public tipoPessoa? : TipoPessoas;

    public documento: string;

    constructor() {
      this.crm = '';
      this.documento = '';
      this.endereco = new Endereco();
    }
}
