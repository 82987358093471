<template>
  <v-card flat class="mt-2 mb-2">
    <v-card-title>Contatos</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" xs="12">
          <!-- <v-card-title>Telefone</v-card-title> -->
            <!-- <h3>Telefone</h3> -->
            <v-row>
              <v-col cols="12" md="5" sm="12">
                <soi-select
                  :name="'Tipo'"
                  :label="'Tipo'"
                  :items="enumTipoTelefone"
                  v-model="contato.tipoTelefonePrincipal"
                  :disabled="disabled"
                >
                </soi-select>
              </v-col>
              <v-col cols="12" md="7" sm="12">
                <soi-input
                  :name="'Telefone'"
                  :label="'Telefone'"
                  v-model="contato.telefonePrincipal"
                  :disabled="disabled"
                  :mask="masks.telefone"
                  :placeholder="'(00) 00000-0000'"
                ></soi-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5" sm="12">
                <soi-select
                  :name="'Tipo'"
                  :label="'Tipo'"
                  :items="enumTipoTelefone"
                  v-model="contato.tipoTelefoneSecundario"
                  :disabled="disabled"
                >
                </soi-select>
              </v-col>
              <v-col cols="12" md="7" sm="12">
                <soi-input
                  :name="'Telefone secundário'"
                  :label="'Telefone secundário'"
                  v-model="contato.telefoneSecundario"
                  :disabled="disabled"
                  :mask="masks.telefone"
                  :placeholder="'(00) 00000-0000'"
                ></soi-input>
              </v-col>
            </v-row>
        </v-col>
        <v-col xs="12">
          <!-- <h3>Email</h3> -->
            <v-row>
              <v-col cols="12" md="5">
                <soi-select
                  :name="'Tipo'"
                  :label="'Tipo'"
                  :items="enumTipoEmail"
                  v-model="contato.tipoEmailPrincipal"
                  :disabled="disabled"
                >
                </soi-select>
              </v-col>
              <v-col cols="12" md="7" sm="12">
                <soi-input
                  :name="'Email'"
                  :label="'Email'"
                  v-model="contato.emailPrincipal"
                  :disabled="disabled"
                  :rules="{ email: true }"
                  :type="'email'"
                  :placeholder="'teste@teste.com'"
                ></soi-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5">
                <soi-select
                  :name="'Tipo'"
                  :label="'Tipo'"
                  :items="enumTipoEmail"
                  v-model="contato.tipoEmailSecundario"
                  :disabled="disabled"
                >
                </soi-select>
              </v-col>
              <v-col cols="12" md="7" sm="12">
                <soi-input
                  :name="'Email secundário'"
                  :label="'Email secundário'"
                  v-model="contato.emailSecundario"
                  :rules="{ email: true }"
                  :disabled="disabled"
                  :type="'email'"
                  :placeholder="'teste@teste.com'"
                ></soi-input>
              </v-col>
            </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import MaskHelper from '../../../helpers/MaskHelper';
import { TipoTelefone, TipoEmail } from '../../../models/Enums';
import Contato from '../../../models/objetosDeValor/Contato';
import Vue from 'vue';

export default Vue.component('soi-contato', {
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      contato: new Contato(),
      enumTipoTelefone: TipoTelefone,
      enumTipoEmail: TipoEmail,
      masks: MaskHelper,
    };
  },
  watch: {
    value() {
      this.contato = this.value;
    },
    contato() {
      this.$emit('input', this.contato);
    },
  },
});
</script>
