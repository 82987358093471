import axios from 'axios';
import { getToken, removeModoNoturno, removeToken } from '../utils/Token';
import Navigation from '../helpers/NavigationHelper';

const httpHeader = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Authorization',
    Authorization: '',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
  },
};

const http = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'https://localhost:7087' : 'https://apimedsystemcloud.metracloud.com.br',
  headers: httpHeader.headers,
});

http.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers!.Authorization = `Bearer ${token}`;
  }
  return config;
}, (err) => Promise.reject(err));

http.interceptors.response.use((response) => response, (error) => {
  if (error.response.config.url !== 'login' && error.response.status === 401) {
    removeToken();
    removeModoNoturno();
    Navigation.navigate('login');
  }
  return Promise.reject(error);
});


export default http;



