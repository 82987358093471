export default class Medicamento {
    public id: number;

    public nome: string;

    public principioAtivo: string;

    public observacao: string;

    constructor() {
      this.id = 0;
      this.nome = '';
      this.principioAtivo = '';
      this.observacao = '';
    }
}
