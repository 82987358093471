import http from './ApiConfig';
import { Service } from './Service';

export class ModelosService extends Service {
  constructor() {
    super('modelos');
  }

  public GetAllPaginado(nomeId: string, usuario: string, tipoModelo: string, limit: number, page: number) {
    return http.get(`${this._nomeControle}?nomeId=${nomeId}&usuario=${usuario}&tipoModelo=${tipoModelo}&limit=${limit}&page=${page}`, this.GetHeader());
  }
}
