<template>
  <v-dialog v-model="modalAdicionarNovoConvenio" persistent width="50%">
    <v-card class="mx-auto">
      <v-toolbar flat dark color="success">
        <v-toolbar-title>
          <v-icon>mdi mdi-content-save-check</v-icon>
          Novo Convênio
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="fecharModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <h5>Geral</h5>
      </v-card-title>
      <validation-observer ref="observer" v-slot="{}">
        <form ref="form" @submit.prevent="salvar">
          <v-card-text>
            <v-row>
              <v-col md="11" sm="12" xs="12" class="d-flex justify-md-center">
                <soi-avatar-input v-model="convenio.file" :fotoKey.sync="convenio.foto"
                  :icon="'mdi-camera'"></soi-avatar-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="9" sm="12" xs="1">
                <soi-input :name="'Nome'" :label="'Nome *'" v-model="convenio.nome"
                  :rules="{ required: true, min: 1 }"></soi-input>
              </v-col>
              <v-col cols="12" md="3" sm="12" xs="1">
                <soi-input :name="'Retorno em dias'" :label="'Período de retorno em dias *'" type="number"
                  v-model="convenio.periodoRetornoDias" :rules="{ min_value: 0 }"></soi-input>
              </v-col>
            </v-row>
            <soi-endereco v-model="convenio.endereco"></soi-endereco>
            <soi-contato v-model="convenio.contato"></soi-contato>
            <lista-plano v-model="convenio.planos" :lista="convenio.planos"></lista-plano>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn color="error" @click="fecharModal">
              <v-icon>mdi-cancel</v-icon>Cancelar</v-btn>
            <v-btn type="submit" color="success" class="mr-2" :loading="carregando">
              <v-icon>mdi mdi-content-save-check</v-icon> Salvar
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Convenio from '@/models/convenio/Convenio';
import { AlertaSimplesErro, MensagemMixin } from '../../helpers/MensagemHelper';
import { ConvenioService } from '@/services/ConveioService';
import ListaPlano from '../../views/convenio/ListaPlano.vue';
import { PlanoService } from '@/services/PlanoService';

export default {
  name: 'soi-adicionar-novo-convenio',
  props: {
  },
  components: {
    ListaPlano,
  },
  data() {
    return {
      modalAdicionarNovoConvenio: false,
      carregando: false,
      convenio: new Convenio(),
      service: new ConvenioService(),
      planoService: new PlanoService(),
    };
  },
  watch: {
    lista(listaPlanos) {
      this.convenio.listaPlanos = listaPlanos;
    },
  },
  mounted() {
    this.convenio = new Convenio();
  },
  methods: {
    abrirModalAdicionarConvenio() {
      this.modalAdicionarNovoConvenio = true;
    },

    fecharModal() {
      this.modalAdicionarNovoConvenio = false;
    },

    async salvar() {
      try {
        const valid = await (this.$refs.observer as any).validate();
        if (!valid) {
          (this.$refs.form as any).scrollIntoView({ behavior: 'smooth' });
          return;
        }
        this.carregando = true;

        const response = await this.service.getAllSelect();
        const convenios = response.data;

        const existeConvenioCadastrado = convenios.some((item) => item.text.toUpperCase() === this.convenio.nome.toUpperCase());

        if (existeConvenioCadastrado) {
          AlertaSimplesErro('Aviso:', 'Já existe um convênio cadastrado com esse nome.');
          this.carregando = false;
          return;
        }

        const postData = new FormData();

        this.convenio.listaPlanos = '';
        this.convenio.planos.forEach((plano) => {
          this.convenio.listaPlanos += this.convenio.listaPlanos + JSON.stringify(plano);
        });
        this.convenio.listaPlanos = `[${this.convenio.listaPlanos.replaceAll('}{', '},{')}]`;

        Object.keys(this.convenio).forEach((key) => {
          if (typeof (this.convenio as any)[key] !== 'undefined' && (this.convenio as any)[key] !== null) {
            if (typeof (this.convenio as any)[key] === 'object' && key !== 'file') {
              Object.keys((this.convenio as any)[key]).forEach((subKey) => {
                if ((this.convenio as any)[key][subKey] !== null && (this.convenio as any)[key][subKey] !== undefined) {
                  postData.append(`${key}.${subKey}`, (this.convenio as any)[key][subKey]);
                }
              });
            } else {
              postData.append(key, (this.convenio as any)[key]);
            }
          }
        });

        await this.service.Post(postData).then(
          (res) => {
            MensagemMixin(3000, 'success', 'Convênio cadastrado com sucesso.');
            this.responseOk = true;
            this.$emit('convenioCadastrado');
          },
          (err) => {
            AlertaSimplesErro('Aviso:', err);
            this.responseOk = false;
          },
        );

        this.carregando = false;
        this.modalAdicionarNovoConvenio = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.carregando = false;
      }
    },
  },
};
</script>
