import Pacientes from '@/models/pacientes/Pacientes';
import http from './ApiConfig';
import { Service } from './Service';

export class PacientesService extends Service {
  constructor() {
    super('pacientes');
  }

  public GetAllPaginado(
    nomeId: string,
    nomeSocial:string,
    dataNascimento: string,
    cpf: string,
    filtroSituacaoPaciente: number,
    listarSomentePacientesComAtendimento: boolean,
    limit: number,
    page: number,
  ) {
    return http.get(`${this._nomeControle}?nomeId=${nomeId}&nomeSocial=${nomeSocial}&dataNascimento=${dataNascimento}&cpf=${cpf}
      &filtroSituacaoPaciente=${filtroSituacaoPaciente}&listarSomentePacientesComAtendimento=${listarSomentePacientesComAtendimento}&limit=${limit}&page=${page}`, this.GetHeader());
  }

  public BuscarPaciente() {
    return http.get(`${this._nomeControle}/buscar`, this.GetHeader());
  }

  public AtivarPaciente(pacienteId: number) {
    return http.patch(`${this._nomeControle}/${pacienteId}/ativar`, this.GetHeader());
  }

  public InativarPaciente(pacienteId: number) {
    return http.patch(`${this._nomeControle}/${pacienteId}/inativar`, this.GetHeader());
  }

  public ObterDadosDoPaciente(pacienteId: number): Promise<Pacientes> {
    return http.get(`${this._nomeControle}/${pacienteId}`, this.GetHeader());
  }

  public MarcarQueOPacienteTemAtendimento(pacienteId: number) {
    return http.patch(`${this._nomeControle}/${pacienteId}/temAtendimento`, this.GetHeader());
  }
}
