import AgendamentoCompromisso from './AgendamentoCompromisso';
import AgendamentoConsulta from './AgendamentoConsulta';
import { SituacaoAgendamento } from '../enums/EnumSituacaoAgendamento';
import { TipoAgendamento } from '../enums/EnumTipoAgendamento';

export default class Agendamento {
    public id: number;

    public agendaId: number;

    public tipoAgendamento: TipoAgendamento;

    public dataAgendamento: string;

    public horaAgendamento: string;

    public duracaoAgendamento: string;

    public situacaoAgendamento: SituacaoAgendamento;

    public agendamentoConfirmado: boolean;

    public observacaoAgendamento: string;

    public agendamentoCompromisso: AgendamentoCompromisso;

    public agendamentoConsulta: AgendamentoConsulta;


    constructor() {
      this.id = 0;
      this.agendaId = 0;
      this.tipoAgendamento = TipoAgendamento.Consulta;
      this.dataAgendamento = '';
      this.situacaoAgendamento = SituacaoAgendamento.Marcado;
      this.horaAgendamento = '';
      this.duracaoAgendamento = '';
      this.agendamentoConfirmado = false;
      this.observacaoAgendamento = '';
      this.agendamentoCompromisso = new AgendamentoCompromisso();
      this.agendamentoConsulta = new AgendamentoConsulta();
    }
}
