import http from './ApiConfig';
import { Service } from './Service';

export class AgendamentoOnlineService extends Service {
  constructor() {
    super('AgendamentoOnline');
  }

  public GerarToken(agendaId:number) {
    return http.get(`${this._nomeControle}/gerarToken/${agendaId}`, this.GetHeader());
  }
}
