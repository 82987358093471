import { TipoDeAtendimento } from '@/models/enums/EnumTipoDeAtendimento';
import http from './ApiConfig';
import { Service } from './Service';

export class AtendimentoService extends Service {
  constructor() {
    super('atendimento');
  }

  public GetAllAtendimentosNaoFinalizados(pacienteId: number) {
    return http.get(`${this._nomeControle}/AtendimentosNaoFinalizados/${pacienteId}`, this.GetHeader());
  }


  public PostListArquivos(atendimentoId: number, modelos: any) {
    return http.post(`${this._nomeControle}/EnviaArquivo/${atendimentoId}`, modelos, this.GetHeader());
  }

  public GeraURLDownloadArquivo(atendimentoId:number) {
    return http.get(`${this._nomeControle}/GeraUrl/${atendimentoId}`, this.GetHeader());
  }

  public DeleteArquivosEmAnexo(atendimentoId:number) {
    return http.delete(`${this._nomeControle}/DeletaAnexo/${atendimentoId}`, this.GetHeader());
  }

  public AtendimentosPorPaciente(pacienteId: number, tipoDeAtendimento: TipoDeAtendimento, dataInicio: string, dataFim: string, limit: number, page: number) {
    return http.get(`${this._nomeControle}/atendimentos-por-paciente?pacienteId=${pacienteId}&tipoDeAtendimento=${tipoDeAtendimento}
          &dataInicio=${dataInicio}&dataFim=${dataFim}&limit=${limit}&page=${page}`, this.GetHeader());
  }

  public GetAtendimentosPorPacienteNaConsulta(pacienteId: number | string) {
    return http.get(`${this._nomeControle}/paciente/${pacienteId}/consultas`, this.GetHeader());
  }
}
