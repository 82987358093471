<template>
    <!-- <b-form-group :label="label" :label-for="name">
        <b-form-radio-group v-model="valor" value-field="key" text-field="name" :name="name" :options="options"
            :disabled="disabled" @input="$emit('input', valor)" />
    </b-form-group> -->
    <v-radio-group
      v-model="valor"
      dense
      row
    >
    <v-radio
        v-for="opcao in items"
        :key="opcao"
        :label="`${opcao.name}`"
        :value="opcao.key"
      ></v-radio>
    </v-radio-group>
</template>


<script lang="ts">
import Vue from 'vue';


export default Vue.component('soi-radio', {


  props: {
    name: {
      type: String,
      default: '',
      required: false,
    },

    label: {
      type: String,
      default: '',
      required: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    value: {
      default: false,
      required: true,
    },

  },

  data() {
    return {
      valor: this.value,
    };
  },

  watch: {
    value() {
      this.valor = this.value;
    },

  },

});
</script>
