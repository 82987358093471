import { SituacaoAgendamento } from '@/models/enums/EnumSituacaoAgendamento';
import http from './ApiConfig';
import { Service } from './Service';
import { TipoAgendamento } from '@/models/enums/EnumTipoAgendamento';
import IAtualizaSituacao from '@/models/interfaces/IAtualizaSituacao';

export class AgendamentoService extends Service {
  constructor() {
    super('Agendamento');
  }

  public GetSalaDeEsperaTodasAgendas() {
    return http.get(`${this._nomeControle}/consulta/sala-de-espera`, this.GetHeader());
  }

  public GetAgendamentoParaTriagem(pacienteId: number) {
    return http.get(`get-agendamento-para-triagem/${pacienteId}`, this.GetHeader());
  }

  public GetSalaDeEsperaPorAgenda(agendaId: number) {
    return http.get(`${this._nomeControle}/consulta/sala-de-espera/${agendaId}`, this.GetHeader());
  }

  public GetAgendamentosParaOCalendario(agendaId: number, mesEAnoParaFiltrar: string) {
    return http.get(`${this._nomeControle}/calendario?agendaId=${agendaId}&mesEAnoDoAgendamento=${mesEAnoParaFiltrar}`, this.GetHeader());
  }

  public GetAgendamentoHorarios(agendaId: number, data: string) {
    return http.get(`${this._nomeControle}/get-horarios-agendamento?agendaId=${agendaId}&data=${data}`, this.GetHeader());
  }

  public BuscarAgendamentos(agendaId: number, dataAgendamento: string, idOuNome: string, descricao: string, limit: number, page: number) {
    return http.get(`${this._nomeControle}/buscar-agendamentos?agendaId=${agendaId}&dataAgendamento=${dataAgendamento}
      &idOuNome=${idOuNome}&descricao=${descricao}&limit=${limit}&page=${page}`, this.GetHeader());
  }

  public BuscarProximaDataDisponivel(agendaId: number) {
    return http.get(`${this._nomeControle}/buscar-proxima-data-disponivel?agendaId=${agendaId}`, this.GetHeader());
  }

  public ExibirQuantidadeDeAgendamentos(pacienteId: number) {
    return http.get(`${this._nomeControle}/somatorio-agendamentos?pacienteId=${pacienteId}`, this.GetHeader());
  }

  public ListarAgendamentosNaDashboard(agendaId: string, pacienteId: number, retorno: boolean, situacao: string, dataInicio: string, dataFim: string, limit: number, page: number) {
    return http.get(`${this._nomeControle}/listar-agendamentos-no-dashboard?agendaId=${agendaId}&pacienteId=${pacienteId}
      &retorno=${retorno}&situacao=${situacao}&dataInicio=${dataInicio}&dataFim=${dataFim}&limit=${limit}&page=${page}`, this.GetHeader());
  }

  public ListarAgendamentosPorTriagem(
    agendaId: number,
    nomeUsuario: string,
    nomePaciente: string,
    dataInicio: string,
    dataFim: string,
    situacao: string,
    retorno: boolean,
    agendamentoConfirmado: boolean,
    limit: number,
    page: number,
  ) {
    return http.get(`${this._nomeControle}/listar-agendamentos-por-triagem?agendaId=${agendaId}&nomeUsuario=${nomeUsuario}
      &nomePaciente=${nomePaciente}&dataInicio=${dataInicio}&dataFim=${dataFim}&situacao=${situacao}&retorno=${retorno}
      &agendamentoConfirmado=${agendamentoConfirmado}&limit=${limit}&page=${page}`, this.GetHeader());
  }

  public atualizaSituacaoAgendamento(idAgendamento: number, situacaoAgendamento: SituacaoAgendamento) {
    return http.patch(`${this._nomeControle}/atualiza-situacao-agendamento?idAgendamento=${idAgendamento}&situacaoAgendamento=${situacaoAgendamento}`, this.GetHeader());
  }

  public atualizaMotivoCancelamento(idAgendamento: number, motivoCancelamento: string) {
    return http.patch(`${this._nomeControle}/atualiza-motivo-cancelamento-agendamento?idAgendamento=${idAgendamento}&motivoCancelamento=${motivoCancelamento}`, this.GetHeader());
  }

  public atualizaSituacaoAgendamentoComLog(idAgendamento: number, payload: IAtualizaSituacao) {
    return http.patch(`${this._nomeControle}/atualiza-situacao-agendamento-com-log?idAgendamento=${idAgendamento}`, payload, this.GetHeader());
  }

  public gerarLinkParaConfirmarAgendamento(idAgendamento: number) {
    return http.get(`${this._nomeControle}/gerar-link-para-confirmar-agendamento?idAgendamento=${idAgendamento}`, this.GetHeader());
  }

  public buscarAgendamentoPorId(idAgendamento: number) {
    return http.get(`${this._nomeControle}/${idAgendamento}`, this.GetHeader());
  }

  public confirmarAgendamento(idAgendamento: number) {
    return http.put(`${this._nomeControle}/confirmar-agendamento?idAgendamento=${idAgendamento}`, null, this.GetHeader());
  }

  public ObterTodosOsAgendamentosDesdeHoje() {
    return http.get(`${this._nomeControle}/obter-todos-agendamentos`, this.GetHeader());
  }
}


