<template>
        <v-switch color="success" inset flat v-model="selecionado"  :label="label" :disabled="disabled">
        </v-switch>
</template>

<script lang="ts">

import Vue from 'vue';

export default Vue.component('soi-switch', {

  props: {
    label: {
      type: String,
      default: '',
      required: true,
    },
    value: {
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selecionado: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.selecionado = newValue;
    },
    selecionado(newValue) {
      this.$emit('input', newValue);
    },
  },
});

</script>
