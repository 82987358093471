<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1118 4.96455C20.1989 5.09674 20.2377 5.25494 20.2217 5.41242C20.2056 5.56991 20.1358
        5.71704 20.0238 5.82897L11.5969 14.255C11.5107 14.3411 11.4031 14.4028 11.2852 14.4337L7.77531 15.3504C7.65929 15.3806 7.53737 15.38
        7.42165 15.3486C7.30594 15.3172 7.20046 15.2561 7.11567 15.1713C7.03089 15.0865 6.96975 14.981 6.93833 14.8653C6.90691 14.7496 6.9063
        14.6277 6.93656 14.5116L7.85323 11.0026C7.87999 10.8973 7.93047 10.7995 8.00081 10.7166L16.4589 2.26405C16.5878 2.1353 16.7625 2.06299
        16.9447 2.06299C17.1269 2.06299 17.3017 2.1353 17.4306 2.26405L20.0238 4.85638C20.0567 4.88939 20.0862 4.92563 20.1118 4.96455ZM18.5654
        5.34222L16.9447 3.72247L9.13656 11.5306L8.56364 13.7242L10.7572 13.1513L18.5654 5.34222Z" fill="#4E4E4E"/>
        <path d="M18.4492 15.7298C18.6997 13.5884 18.7797 11.4305 18.6884 9.27648C18.6864 9.22573 18.6949 9.17512 18.7134 9.1278C18.7318 9.08049 18.7598
        9.03748 18.7957 9.00148L19.6977 8.09948C19.7223 8.07469 19.7536 8.05755 19.7878 8.05011C19.8219 8.04268 19.8575 8.04526 19.8902 8.05756C19.9229
        8.06985 19.9514 8.09133 19.9722 8.11942C19.993 8.14751 20.0053 8.18101 20.0075 8.2159C20.1773 10.7745 20.1128 13.3433 19.815 15.8902C19.5987
        17.7437 18.11 19.1966 16.2647 19.4029C13.0613 19.7577 9.82851 19.7577 6.62508 19.4029C4.78075 19.1966 3.29117 17.7437 3.07483 15.8902C2.69478
        12.641 2.69478 9.3586 3.07483 6.1094C3.29117 4.2559 4.77983 2.80298 6.62508 2.59673C9.05642 2.32712 11.5061 2.26181 13.9483 2.40148C13.9833
        2.40399 14.0168 2.41646 14.0449 2.43743C14.0729 2.45839 14.0944 2.48696 14.1068 2.51975C14.1191 2.55254 14.1218 2.58819 14.1145 2.62247C14.1072
        2.65674 14.0903 2.68821 14.0657 2.71315L13.1554 3.62248C13.1198 3.658 13.0772 3.68584 13.0304 3.70429C12.9836 3.72273 12.9335 3.73139 12.8832
        3.72973C10.8452 3.66045 8.80488 3.73857 6.77817 3.96348C6.18594 4.02903 5.63311 4.29231 5.20897 4.71079C4.78484 5.12928 4.51416 5.67853 4.44067
        6.26981C4.07313 9.41244 4.07313 12.5872 4.44067 15.7298C4.51416 16.3211 4.78484 16.8704 5.20897 17.2888C5.63311 17.7073 6.18594 17.9706 6.77817
        18.0361C9.85358 18.3799 13.0362 18.3799 16.1126 18.0361C16.7048 17.9706 17.2576 17.7073 17.6818 17.2888C18.1059 16.8704 18.3757 16.3211 18.4492 15.7298Z" fill="#4E4E4E"/>
    </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.component('soi-icon-edit', {

});
</script>
