<template>
    <v-dialog v-model="modalAberto" transition="dialog-bottom-transition" max-width="900" persistent>
        <v-card class="mx-auto" :loading="carregando ? 'success' : null" :disabled="carregando">
            <v-toolbar flat outlined>
                <v-toolbar-title>
                    <v-icon color="success" left size="x-large">
                        mdi-bookmark-plus-outline
                    </v-icon>
                    {{ titulo }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="fecharModal()">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" sm="12">
                        <v-data-table :loading="carregando ? 'success' : null"
                            no-data-text="Nenhum(a) Atendimento não Finalizado encontrado(a)!"
                            loading-text="Carregando os itens desta tabela! Aguarde..."
                            :headers="colunasAtendimentosNaoFinalizados" :items-per-page="-1" hide-default-footer
                            :items="atendimentos" class="elevation-0">
                            <template v-slot:item.actions="{ item }">
                                <v-row class="justify-center align-center">
                                    <v-btn id="continuar" class="mx-2" x-small fab color="success"
                                        @click="continuarAtendimento(item.id)" title="Continuar Atendimento">
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn id="descartar" class="mx-2" x-small fab color="error"
                                        @click="excluirAtendimentoNaoFinalizado(item.id)" title="Descartar Atendimento">
                                        <v-icon small>mdi-minus</v-icon>
                                    </v-btn>
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import DateHelper from '@/helpers/DateHelper';
import { AlertaExclusaoQuestaoBooleano, AlertaSimplesErro, MensagemMixin } from '@/helpers/MensagemHelper';
import Agendamento from '@/models/agendamento/Agendamento';
import Atendimento from '@/models/atendimento/Atendimento';
import { SituacaoAgendamento } from '@/models/enums/EnumSituacaoAgendamento';
import Pacientes from '@/models/pacientes/Pacientes';
import { AgendamentoService } from '@/services/AgendamentoService';
import { AtendimentoService } from '@/services/AtendimentoService';
import { PacientesService } from '@/services/PacientesService';
import Vue from 'vue';

export default Vue.component('soi-modalAtendimentosNaoFinalizados', {
  name: 'soi-modalAtendimentosNaoFinalizados',
  props: {
    atendimentoId: {
      type: Number,
    },
  },
  data() {
    return {
      modalAberto: false,
      atendimentos: [],
      paciente: new Pacientes(),
      pacienteService: new PacientesService(),
      idPaciente: parseInt(this.$route.params.pacienteId, 10),
      atendimento: new Atendimento(),
      serviceAgendamento: new AgendamentoService(),
      service: new AtendimentoService(),
      pacienteId: 0,
      carregando: false,
      agendamento: new Agendamento(),
      salaDeEspera: false,
      colunasAtendimentosNaoFinalizados: [
        {
          text: 'Data do Atendimento',
          align: 'center',
          value: 'dataAtendimento',
          filterable: true,
          sortable: true,
        },
        {
          text: 'Paciente',
          align: 'center',
          value: 'paciente.nomeCompleto',
          filterable: true,
        },
        {
          text: 'Médico',
          align: 'center',
          value: 'encaminhamento.usuario.nomeCompleto',
          filterable: true,
        },
        {
          text: 'Ações',
          align: 'center',
          value: 'actions',
        },
      ],
    };
  },
  computed: {
    titulo() {
      return 'Atendimento não finalizado';
    },
  },
  methods: {
    fecharModal() {
      this.$emit('fechandoModal');
      this.modalAberto = false;
    },
    async AgendamentoEmAndamento(): Promise<Boolean> {
      return this.serviceAgendamento.atualizaSituacaoAgendamento(this.agendamento.id, SituacaoAgendamento.EmAndamento).then(
        () => true,
        (err) => {
          AlertaSimplesErro('Não foi possível colocar em Andamento este agendamento!', err);
          return false;
        },
      );
    },
    async abrirModalAtendimentosNaoFinalizados(pacienteId: number) {
      this.pacienteId = pacienteId;
      await this.listarAtendimentosNaoFinalizados(pacienteId);

      if (this.atendimentos.length > 0) {
        this.modalAberto = true;
      } else {
        MensagemMixin(3000, 'info', 'Iniciando novo atendimento.');
        this.$router.push({ name: 'atendimentoForm', params: { pacienteId, agendamentoId: 0 } });
      }
    },
    async abrirModalAtendimentosNaoFinalizadosSalaDeEspera(agendamento: Agendamento) {
      this.salaDeEspera = true;
      this.agendamento = agendamento;
      this.pacienteId = agendamento.agendamentoConsulta.pacienteId;
      await this.listarAtendimentosNaoFinalizados(this.pacienteId);
      if (this.atendimentos.length > 0) {
        this.modalAberto = true;
      } else if (await this.AgendamentoEmAndamento()) {
        this.$router.push({ name: 'atendimentoForm', params: { pacienteId: this.pacienteId, agendamentoId: agendamento.id } });
      }
    },
    async listarAtendimentosNaoFinalizados(pacienteId: number) {
      this.carregando = true;
      this.pacienteId = pacienteId;
      this.atendimentos = [];
      await this.service.GetAllAtendimentosNaoFinalizados(pacienteId).then(
        (res) => {
          this.atendimentos = res.data;

          if (this.atendimentos.length === 0) {
            MensagemMixin(3000, 'info', 'Iniciando um novo atendimento.');
          }

          this.atendimentos.forEach((atendimento) => {
            // eslint-disable-next-line no-param-reassign
            atendimento.dataAtendimento = DateHelper.formataParaDiaMesAno(atendimento.dataAtendimento);
          });
        },
        (err) => { },
      );
      this.carregando = false;
    },
    async continuarAtendimento(atendimentoId: number) {
      if (this.salaDeEspera) {
        if (await this.AgendamentoEmAndamento()) {
          this.$router.push({ name: 'atendimentoFormUpdate', params: { pacienteId: this.pacienteId, idAtendimento: atendimentoId } });
        }
      } else {
        this.$router.push({ name: 'atendimentoFormUpdate', params: { pacienteId: this.pacienteId, idAtendimento: atendimentoId } });
      }
    },
    async excluirAtendimentoNaoFinalizado(atendimentoId: number) {
      if (await AlertaExclusaoQuestaoBooleano()) {
        await this.service.Delete(atendimentoId).then(
          (res) => {
            MensagemMixin(3000, 'success', 'Atendimento foi descartado com sucesso');
            this.$emit('AgendamentoEmAndamento');
            this.modalAberto = false;
          },
          (err) => {
            AlertaSimplesErro('Aviso:', err);
          },
        );
      }
    },

  },
});
</script>
