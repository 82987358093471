import { TipoEmail, TipoTelefone } from '../enums/EnumTipoContato';

export default class Contato {
    public tipoTelefonePrincipal?: TipoTelefone;

    public telefonePrincipal: string;

    public tipoTelefoneSecundario?: TipoTelefone;

    public telefoneSecundario: string;

    public tipoEmailPrincipal?: TipoEmail;

    public emailPrincipal: string;

    public tipoEmailSecundario?: TipoEmail;

    public emailSecundario: string;

    constructor() {
      this.telefonePrincipal = '';
      this.telefoneSecundario = '';
      this.emailPrincipal = '';
      this.emailSecundario = '';
    }
}
