import http from './ApiConfig';
import { Service } from './Service';

export class MedicamentosService extends Service {
  constructor() {
    super('medicamentos');
  }

  public GetAllPaginado(nomeId: string, principioAtivo: string, medicamentoPadrao: boolean, limit: number, page: number) {
    return http.get(`${this._nomeControle}/visualizar-todos?nomeId=${nomeId}&principioAtivo=${principioAtivo}&medicamentoPadrao=${medicamentoPadrao}&limit=${limit}&page=${page}`, this.GetHeader());
  }

  public BuscarMedicamento(medicamentoPadrao: boolean) {
    return http.get(`${this._nomeControle}/buscar-medicamentos?medicamentoPadrao=${medicamentoPadrao}`, this.GetHeader());
  }

  public GetBula(anvisa: string) {
    return http.get(`${this._nomeControle}/acessar-bula?anvisa=${anvisa}`, this.GetHeader());
  }
}
