<template>
    <v-card outlined class="mx-2 my-2">
        <v-container>
            <v-row>
              <v-col cols="12" md="6" lg="3">
                <v-card color="blue darken-3" dark>
                  <v-card-title class="justify-center">
                    Marcados
                  </v-card-title>
                  <v-card-text class="text-center text-h3 font-weight-bold">
                    {{ quantidadeMarcado }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-card color="green darken-1" dark>
                  <v-card-title class="justify-center">
                        Finalizados
                  </v-card-title>
                  <v-card-text class="text-center text-h3 font-weight-bold">
                    {{ quantidadeFinalizado }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-card color="red darken-1" dark>
                  <v-card-title class="justify-center">
                        Faltas
                  </v-card-title>
                  <v-card-text class="text-center text-h3 font-weight-bold">
                    {{ quantidadeFaltou }}
                </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-card color="orange darken-1" dark>
                  <v-card-title class="justify-center">
                        Cancelados
                  </v-card-title>
                  <v-card-text class="text-center text-h3 font-weight-bold">
                    {{ quantidadeCancelado }}
                </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-card outlined class="mx-2 my-2">
                <v-card-title>
                    Filtros
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey darken-3"
                        dark
                        text
                        x-small
                        @click="limparPesquisa()"
                    >
                        <v-icon x-small>mdi mdi-close</v-icon>
                        Limpar
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="2">
                            <soi-select
                                v-model="agendaSelecionada"
                                label="Agendas"
                                :items="agendas"
                                @input="listar()"
                            ></soi-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                            <soi-select
                                v-model="retornoOuConsulta"
                                label="Tipo"
                                :items="tipoConsulta"
                                @input="listar()"
                            ></soi-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                            <soi-select
                                v-model="situacaoSelecionada"
                                label="Situação"
                                :items="status"
                                @input="listar()"
                            ></soi-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <soi-input :name="'Data Inicial'" :label="'Data Inicial'" v-model="dataInicio" type="date"
                            @input="listar()"
                            ></soi-input>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <soi-input :name="'Data Final'" :label="'Data Final'" v-model="dataFim" type="date"
                            @input="listar()"
                            ></soi-input>
                        </v-col>
                    </v-row>
                    <v-data-table class="elevation-1" :headers="colunas" no-data-text="Nenhum agendamento encontrado!" :items-per-page="itens"
                            :items="listaAgendamentos" :page.sync="paginaAtual" :footer-props="footerProps"
                            :header-props="headerProps">
                            <template v-slot:item.tipoAgendamento="{ item }">
                                <span v-if="item.retorno">Retorno</span>
                                <span v-else>Consulta</span>
                            </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
          </v-container>
    </v-card>
</template>

<script lang="ts">
import DateHelper from '@/helpers/DateHelper';
import { AlertaSimplesErro } from '@/helpers/MensagemHelper';
import { SituacaoAgendamento } from '@/models/enums/EnumSituacaoAgendamento';
import { AgendaService } from '@/services/AgendaService';
import { AgendamentoService } from '@/services/AgendamentoService';
import Vue from 'vue';

export default Vue.extend({
  name: 'soi-dashboard-agendamentos',

  data() {
    return {
      carregando: false,
      quantidadeMarcado: 0,
      quantidadeFinalizado: 0,
      pacienteId: 1,
      quantidadeFaltou: 0,
      quantidadeCancelado: 0,
      agendaSelecionada: '',
      retornoOuConsulta: '',
      situacaoSelecionada: '',
      tipoSelecionado: '',
      dataInicio: '',
      dataFim: '',
      agendamentoService: new AgendamentoService(),
      agendaService: new AgendaService(),
      tipoConsulta: [
        { value: '', text: 'Todos' },
        { value: 'false', text: 'Consulta' },
        { value: 'true', text: 'Retorno' },
      ],
      status: [
        { value: '', text: 'Todos' },
        { value: '0', text: 'Marcado' },
        { value: '1', text: 'Em Espera' },
        { value: '2', text: 'EmAndamento' },
        { value: '3', text: 'Faltou' },
        { value: '4', text: 'Finalizado' },
        { value: '5', text: 'Cancelado' },
      ],
      agendamentos: [],
      listaAgendamentos: [],
      agendas: [],
      cont: 0,
      colunas: [
        {
          text: 'Tipo',
          align: 'start',
          value: 'tipoAgendamento',
          filterable: true,
          sortable: true,
        },
        {
          text: 'Situação',
          value: 'situacaoAgendamentoDescrito',
          filterable: true,
          sortable: true,
        },
        {
          text: 'Data',
          value: 'dataAgendamento',
          filterable: true,
          sortable: true,
        },
        {
          text: 'Chegada',
          value: 'horaChegada',
          filterable: true,
          sortable: true,
        },
        {
          text: 'Agenda',
          value: 'agenda.descricao',
          filterable: true,
          sortable: true,
        },
      ],
      headerProps: {
        sortByText: 'Ordenar por',
      },
      footerProps: {
        itemsPerPageOptions: [5, 10, 15],
        itemsPerPageText: 'Agendamentos por página',
        pageText: '{0}-{1} de {2}',
      },
      valorFiltro: 0,
      paginaAtual: 1,
      itens: 5,
      situacaoAgendamento: SituacaoAgendamento,
    };
  },
  mounted() {
    this.$nextTick(async function () {
      await this.agendaService.GetAll().then(
        (res) => {
          const agendasRetornadas = res.data;
          const agendasMapeadas = agendasRetornadas.map((agenda) => ({
            value: agenda.id,
            text: agenda.descricao,
          }));
          this.agendas = [{ value: '', text: 'Todas' }, ...agendasMapeadas];
        },
        (err) => {
          this.agenda = undefined;
          AlertaSimplesErro('Houve um problema ao buscar as agendas.', err);
        },
      );
    });

    this.exibirQuantidadeDeAgendamentos();
    this.listar();
  },
  computed: {
    selectedStatusValue() {
      if (this.situacaoSelecionada === '') {
        return '';
      }
      return this.status.find((s) => s.text === this.situacaoSelecionada).value;
    },
    tipoSelecionadoValue() {
      if (this.retornoOuConsulta === '') {
        return '';
      }
      return this.tipoConsulta.find((s) => s.text === this.retornoOuConsulta).value;
    },
  },
  methods: {
    async exibirQuantidadeDeAgendamentos() {
      await this.agendamentoService.ExibirQuantidadeDeAgendamentos(this.pacienteId).then((res) => {
        this.agendamentos = res.data;
        this.quantidadeMarcado = res.data.quantidadeMarcado;
        this.quantidadeFaltou = res.data.quantidadeFaltou;
        this.quantidadeCancelado = res.data.quantidadeCancelado;
        this.quantidadeFinalizado = res.data.quantidadeFinalizado;
      });
    },
    limparPesquisa() {
      this.paginaAtual = 1;
      this.agendaSelecionada = '';
      this.retornoOuConsulta = '';
      this.situacaoSelecionada = '';
      this.dataInicio = '';
      this.dataFim = '';

      this.listar();
    },
    async listar() {
      this.carregando = true;
      this.paginaAtual = 1;

      await this.agendamentoService
        .ListarAgendamentosNaDashboard(
          this.agendaSelecionada ? this.agendaSelecionada : '',
          this.pacienteId,
          this.retornoOuConsulta ? this.retornoOuConsulta : '',
          this.situacaoSelecionada ? this.situacaoSelecionada : '',
          this.dataInicio ? this.dataInicio : '',
          this.dataFim ? this.dataFim : '',
          this.itens,
          this.paginaAtual,
        )
        .then(
          (res) => {
            this.listaAgendamentos = res.data.itens;
            this.totalPaginas = res.data.totalPaginas;
            this.paginaAtual = res.data.paginaAtual;
            this.listaAgendamentos.forEach((agendamento) => {
              // eslint-disable-next-line no-param-reassign
              agendamento.dataAgendamento = DateHelper.formataParaDiaMesAno(
                agendamento.dataAgendamento,
              );
            });
          },
          (err) => {
            AlertaSimplesErro('Aviso:', err);
          },
        );
      this.carregando = false;
    },
  },
});
</script>
