enum PermissoesPadrao {
    UsuarioReadAll = 'UsuarioReadAll',
    UsuarioRead = 'UsuarioRead',
    UsuarioCreate = 'UsuarioCreate',
    UsuarioUpdate = 'UsuarioUpdate',
    UsuarioDelete = 'UsuarioDelete',

    ProfileUpdate = 'ProfileUpdate',

    ClinicaRead = 'ClinicaRead',
    ClinicaUpdate = 'ClinicaUpdate',

    PacienteReadAll = 'PacienteReadAll',
    PacienteRead = 'PacienteRead',
    PacienteCreate = 'PacienteCreate',
    PacienteUpdate = 'PacienteUpdate',
    PacienteDelete = 'PacienteDelete',

    RoleReadAll = 'RoleReadAll',
    RoleRead = 'RoleRead',
    RoleCreate = 'RoleCreate',
    RoleUpdate = 'RoleUpdate',
    RoleDelete = 'RoleDelete',

    AgendaUpdate = 'AgendaUpdate',
    AgendaCreate = 'AgendaCreate',
    AgendaRead = 'AgendaRead',
    AgendaDelete = 'AgendaDelete',
    AgendaReadAll = 'AgendaReadAll',

    AgendamentoRead = 'AgendamentoRead',
    AgendamentoCreate = 'AgendamentoCreate',
    AgendamentoUpdate = 'AgendamentoUpdate',
    AgendamentoDelete = 'AgendamentoDelete',

    ConvenioReadAll= 'ConvenioReadAll',
    ConvenioRead = 'ConvenioRead',
    ConvenioCreate = 'ConvenioCreate',
    ConvenioUpdate = 'ConvenioUpdate',
    ConvenioDelete = 'ConvenioDelete',

    TenantReadAll = 'TenantReadAll',
    TenantRead = 'TenantRead',
    TenantCreate = 'TenantCreate',
    TenantUpdate = 'TenantUpdate',
    TenantDelete = 'TenantDelete',
    TenantPatch = 'TenantPatch',

    SalaDeEsperaRead = 'SalaDeEsperaRead',

    AtendimentoReadAll= 'AtendimentoReadAll',
    AtendimentoRead = 'AtendimentoRead',
    AtendimentoCreate = 'AtendimentoCreate',
    AtendimentoUpdate = 'AtendimentoUpdate',
    AtendimentoDelete = 'AtendimentoDelete',

    ModeloReadAll= 'ModeloReadAll',
    ModeloRead = 'ModeloRead',
    ModeloCreate = 'ModeloCreate',
    ModeloUpdate = 'ModeloUpdate',
    ModeloDelete = 'ModeloDelete',

    AuditoriaReadAll = 'AuditoriaReadAll',
    AuditoriaRead = 'AuditoriaRead',

    AcessoTriagem = 'AcessoTriagem',
    TriagemReadAll = 'TriagemReadAll',
    TriagemRead = 'TriagemRead',
    TriagemCreate = 'TriagemCreate',
    TriagemUpdate = 'TriagemUpdate',
    TriagemDelete = 'TriagemDelete',

    ImpressoPersonalizadoReadAll = 'ImpressoPersonalizadoReadAll',
    ImpressoPersonalizadoRead = 'ImpressoPersonalizadoRead',
    ImpressoPersonalizadoCreate = 'ImpressoPersonalizadoCreate',
    ImpressoPersonalizadoUpdate = 'ImpressoPersonalizadoUpdate',

    AcessoConfiguracaoCampos = 'AcessoConfiguracaoCampos',
    ConfiguracaoCamposReadAll = 'ConfiguracaoCamposReadAll',
    ConfiguracaoCamposRead = 'ConfiguracaoCamposRead',
    ConfiguracaoCamposCreate = 'ConfiguracaoCamposCreate',
    ConfiguracaoCamposUpdate = 'ConfiguracaoCamposUpdate',
    ConfiguracaoCamposDelete = 'ConfiguracaoCamposDelete'
}
export default PermissoesPadrao;
