import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';
import ClinicMedicalIcon from '../components/icons/ClinicMedicalIcon.vue';
import ProfileIcon from '../components/icons/ProfileIcon.vue';
import DarkModeIcon from '../components/icons/DarkModeIcon.vue';
import LightModeIcon from '@/components/icons/LightModeIcon.vue';
import LogoutIcon from '@/components/icons/LogoutIcon.vue';
import MenuIcon from '@/components/icons/MenuIcon.vue';
import BriefcaseMedicalIcon from '@/components/icons/BriefcaseMedicalIcon.vue';
import DocumentAddIcon from '@/components/icons/DocumentAddIcon.vue';
import ReportBoxIcon from '@/components/icons/ReportBoxIcon.vue';
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue';
import HomeIcon from '@/components/icons/HomeIcon.vue';
import CalendarIcon from '@/components/icons/CalendarIcon.vue';
import DisplaySettingsIcon from '@/components/icons/DisplaySettingsIcon.vue';
import HeadSetIcon from '@/components/icons/HeadSetIcon.vue';
import WhatsAppIcon from '@/components/icons/WhatsAppIcon.vue';
import EnvelopeIcon from '@/components/icons/EnvelopeIcon.vue';
import PrintIcon from '@/components/icons/FluentPrintIcon.vue';
import EditIcon from '@/components/icons/EditIcon.vue';
import AccountCheck from '@/components/icons/AccountCheck.vue';
import AccountOff from '@/components/icons/AccountOff.vue';
import DeleteIcon from '@/components/icons/DeleteIcon.vue';
import ArrowLeft from '@/components/icons/ArrowLeft.vue';
import ArrowRight from '@/components/icons/ArrowRight.vue';
import SaveIcon from '@/components/icons/SaveIcon.vue';
import CancelIcon from '@/components/icons/CancelIcon.vue';
import DoctorIcon from '@/components/icons/DoctorIcon.vue';
import InfoOutline from '@/components/icons/InfoOutline.vue';
import ArrowPageRight from '@/components/icons/ArrowPageRightIcon.vue';
import ArrowPageLeft from '@/components/icons/ArrowPageLeftIcon.vue';
import SearchIcon from '@/components/icons/SearchIcon.vue';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    dark: false,
    default: 'light',
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#009A3C',
        secondary: '#FFCA5A',
        success: '#10A860',
        customGray: '#DDE1E7',
      },
      dark: {
        primary: '#009A3C',
        secondary: '#FFCA5A',
        success: '#10A860',
        customGray: '#DDE1E7',
      },
    },
  },
  icons: {
    values: {
      clinical: {
        component: ClinicMedicalIcon,
      },
      profile: {
        component: ProfileIcon,
      },
      darkMode: {
        component: DarkModeIcon,
      },
      lightMode: {
        component: LightModeIcon,
      },
      logout: {
        component: LogoutIcon,
      },
      menu: {
        component: MenuIcon,
      },
      briefcase: {
        component: BriefcaseMedicalIcon,
      },
      documentAdd: {
        component: DocumentAddIcon,
      },
      reportBox: {
        component: ReportBoxIcon,
      },
      arrowRight: {
        component: ArrowRightIcon,
      },
      home: {
        component: HomeIcon,
      },
      calendar: {
        component: CalendarIcon,
      },
      displaySettings: {
        component: DisplaySettingsIcon,
      },
      headSet: {
        component: HeadSetIcon,
      },
      whatsApp: {
        component: WhatsAppIcon,
      },
      envelope: {
        component: EnvelopeIcon,
      },
      print: {
        component: PrintIcon,
      },
      edit: {
        component: EditIcon,
      },
      search: {
        component: SearchIcon,
      },
      accountcheck: {
        component: AccountCheck,
      },
      accountoff: {
        component: AccountOff,
      },

      delete: {
        component: DeleteIcon,
      },
      arrowleft: {
        component: ArrowLeft,
      },
      arrowright: {
        component: ArrowRight,
      },
      saveIcon: {
        component: SaveIcon,
      },
      cancelIcon: {
        component: CancelIcon,
      },
      doctor: {
        component: DoctorIcon,
      },
      infoOutline: {
        component: InfoOutline,
      },
      arrowPageRight: {
        component: ArrowPageRight,
      },
      arrowPageLeft: {
        component: ArrowPageLeft,
      },
    },
  },
});
