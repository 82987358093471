import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

export default class DateHelper {
  static formataParaDiaMesAnoISO(dataAgendamento: string): string {
    throw new Error('Method not implemented.');
  }

    static DiaMesAno: any;

    public static formatoBR(dataAformatar: string) {
      if (dataAformatar) {
        const date = new Date(dataAformatar);
        if (date instanceof Date) {
          return date.toLocaleDateString('pt-BR');
        }
        throw new Error('Data Inválida');
      }
      return '';
    }

    public static calculaIdade(dataDeNascimento: string) {
      const hoje = moment();
      const aniversario = moment(dataDeNascimento);

      const idade = hoje.diff(aniversario, 'years');
      if (idade > 0) {
        return idade;
      }

      return 0;
    }

    public static calculaIdadePorExtenso(dataNascimento: string) {
      if (!dataNascimento) {
        return '';
      }

      const dataAtual = new Date();
      const anoAtual = dataAtual.getFullYear();
      const mesAtual = dataAtual.getMonth() + 1;
      const diaAtual = dataAtual.getDate();

      const dataNascimentoArray = dataNascimento.split('-');
      const anoNascimento = parseInt(dataNascimentoArray[0], 10);
      const mesNascimento = parseInt(dataNascimentoArray[1], 10);
      const diaNascimento = parseInt(dataNascimentoArray[2], 10);

      let idadeAnos = anoAtual - anoNascimento;
      let idadeMeses = mesAtual - mesNascimento;
      let idadeDias = diaAtual - diaNascimento;

      if (idadeDias < 0) {
        idadeMeses--;
        const ultimoDiaMesAnterior = new Date(anoAtual, mesAtual - 1, 0).getDate();
        idadeDias += ultimoDiaMesAnterior;
      }

      if (idadeMeses < 0) {
        idadeAnos--;
        idadeMeses += 12;
      }

      return `${idadeAnos} anos ${idadeMeses} meses ${idadeDias} dias`;
    }

    public static dataTempoAgora(dataAFormatar: string) {
      const data = moment(dataAFormatar);
      return moment(data).format('YYYY-MM-DD').toString();
    }

    public static tempoRelativo(dataPassada: string) {
      const data = moment(dataPassada);
      const diferenca = data.diff(moment.now(), 'seconds') * -1;
      return moment.utc(diferenca * 1000).format('HH:mm:ss');
    }

    public static formataParaDiaMesAno(dataAFormatar: string) {
      const data = moment(dataAFormatar);
      return moment(data).format('DD/MM/YYYY');
    }

    public static formataParaHoraMinutoSegundo(dataAFormatar: string) {
      const data = moment(dataAFormatar);
      return moment(data).format('LTS');
    }

    public static formataParaAnoMesDia(dataAFormatar: string) {
      moment.locale('pt-br');
      const data = moment(dataAFormatar);
      return data.format('YYYY-MM-DD');
    }

    public static formataParaAnoDiaMes(dataAFormatar: string) {
      const data = moment(dataAFormatar);
      return moment(data).format('YYYY-DD-MM');
    }

    public static dataMaiorQueHoje(data: string) {
      const hoje = moment(moment().format('YYYY-MM-DD'));
      const dataParametro = moment(data);

      const maiorQueHoje = hoje.isBefore(dataParametro);
      if (maiorQueHoje) {
        return true;
      }

      return false;
    }

    public static dataMenorQueHoje(data: string) {
      const hoje = moment(moment().format('YYYY-MM-DD'));
      const dataParametro = moment(data);

      const menorQueHoje = hoje.isAfter(dataParametro);
      if (menorQueHoje) {
        return true;
      }

      return false;
    }

    public static preencherData() {
      const data = moment().format('DD/MM/YYYY');
      return data;
    }

    public static formataParaAnoMesDiaISO8601(data:string) {
      const dataIso = `${data.substring(6, 10)}-${data.substring(3, 5)}-${data.substring(0, 2)}`;
      return new Date(dataIso).toISOString();
    }

    public static formatarParaAnosMesDiaISO(data:string) {
      return new Date(data).toISOString();
    }

    public static formatarParaAnosMesDiaSubstring(data:string) {
      const dataIso = `${data.substring(6, 10)}-${data.substring(3, 5)}-${data.substring(0, 2)}`;
      return moment(dataIso).format('YYYY-MM-DD');
    }

    public static retornaDataAtual(data) {
      return moment(data).isSame(moment(), 'day');
    }

    public static exibeDataHora(dataHora: string) {
      const formatoEntrada = 'YYYY-MM-DDTHH:mm:ss.SSS';
      const formatoSaida = 'DD/MM/YYYY [às] HH:mm';

      const dataConvertida = moment(dataHora, formatoEntrada).format(formatoSaida);

      return dataConvertida;
    }
}
