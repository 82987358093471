import Agendamento from '../agendamento/Agendamento';
import Consulta from '../consulta/Consulta';
import Encaminhamento from '../encaminhamento/Encaminhamento';
import { TipoDeAtendimento } from '../enums/EnumTipoDeAtendimento';
import ExamesComplementares from '../examesComplementares/ExamesComplementares';
import Laudo from '../laudos/Laudo';
import Pacientes from '../pacientes/Pacientes';
import Prescricao from '../prescricao/Prescricao';
import Rotina from '../rotinas/Rotina';

export default class Atendimento {
    public id: number;

    public pacienteId: number;

    public agendamentoId: number;

    public agendamento: Agendamento;

    public paciente: Pacientes;

    public consultaId: number;

    public consulta: Consulta;

    public encaminhamentoId: number;

    public encaminhamento: Encaminhamento;

    public rotinaId: number;

    public rotina: Rotina;

    public laudoId: number;

    public laudo: Laudo;

    public prescricaoId: number;

    public prescricao: Prescricao;

    public examesComplementaresId: number;

    public examesComplementares: ExamesComplementares;

    public observacaoAtendimentoExibicao: string;

    public finalizado: boolean;

    public dataAtendimento: string;

    public tiposDeAtendimento: TipoDeAtendimento | TipoDeAtendimento[];

    constructor() {
      this.id = 0;
      this.consultaId = 0;
      this.consulta = new Consulta();
      this.pacienteId = 0;
      this.agendamentoId = null;
      this.agendamento = new Agendamento();
      this.paciente = new Pacientes();
      this.encaminhamentoId = 0;
      this.encaminhamento = new Encaminhamento();
      this.rotinaId = 0;
      this.rotina = new Rotina();
      this.laudoId = 0;
      this.laudo = new Laudo();
      this.prescricaoId = 0;
      this.prescricao = new Prescricao();
      this.examesComplementaresId = 0;
      this.examesComplementares = new ExamesComplementares();
      this.observacaoAtendimentoExibicao = '';
      this.finalizado = false;
    }
}
