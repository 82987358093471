<template>
  <div>
    <v-row >
      <v-col>
        <v-avatar  class="avatar" color="#A5D6A7" size="14rem" @click="disabled? '' : abrirModal()">
          <img v-if="urlImagem" :src="urlImagem">
          <v-icon v-else dark class="d-flex justify-center" size="100">{{ icon }}</v-icon>
        </v-avatar>
      </v-col>
    </v-row>
    <v-dialog v-model="mostrarModal" persistent max-width="50rem">
        <v-card class="mx-auto">
            <v-toolbar flat outlined>
                <v-toolbar-title><v-icon color="success" left size="x-large">mdi-camera-iris</v-icon>Adicionar Imagem</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="fecharModal()"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row v-if="$vuetify.breakpoint.smAndUp" class="d-flex flex-row justify-center align-center mx-2 my-2">
                        <div class="button-container">
                            <v-btn class="mr-2 mb-2 mt-2" color="success">
                                <v-icon>mdi-upload</v-icon>
                                Carregar Imagem
                            </v-btn>
                            <input
                                id="inputFile"
                                type="file"
                                accept="image/png, image/jpeg"
                                class="inputfile"
                                @input="alteraArquivoModal"
                            />
                            <v-btn class="mb-2 mt-2" color="success" @click="checarWebcam">
                                <v-icon>mdi-camera</v-icon>
                                Iniciar Câmera
                            </v-btn>
                        </div>
                </v-row>
                <v-row v-else cols="12">
                        <div class="button-container">
                            <v-btn class="mb-2 mt-6" color="success" width="100%">
                                <div class="d-flex align-center">
                                    <v-icon>mdi-upload</v-icon>
                                </div>
                                <div class="flex-grow-1 text-center">
                                    Carregar Imagem
                                </div>
                            </v-btn>
                            <input
                                id="inputFile"
                                type="file"
                                accept="image/png, image/jpeg"
                                class="inputfile"
                                @change="alteraArquivoModal"
                            />
                            <v-btn class="mb-4" color="success" width="100%" @click="checarWebcam">
                                <div class="d-flex align-center">
                                    <v-icon>mdi-camera</v-icon>
                                </div>
                                <div class="flex-grow-1 text-center">
                                    Iniciar Câmera
                                </div>
                            </v-btn>
                        </div>
                    </v-row>
                    <template v-if="arquivoModal !== '' && cameraAtiva === false">
                        <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex justify-center pb-4 pt-4">
                          <v-avatar size="10rem" class="ml-3" :src="urlModal" v-if="!showCropper">
                            <img :src="urlModal" />
                          </v-avatar>
                        </div>
                        <div v-else>
                            <div class="d-flex justify-center pb-4 pt-4">
                                <v-avatar size="6rem" class="ml-3" :src="urlModal" v-if="!showCropper">
                                    <img :src="urlModal" />
                                </v-avatar>
                            </div>
                        </div>
                        <template v-if="showCropper">
                            <cropper
                                ref="cropper"
                                class="mb-2"
                                :src="cropperImage"
                                stencil-component="circle-stencil"
                                :stencil-props="{
                                    handlers: {},
                                    movable: false,
                                    resizable: false,
                                }"
                                :stencil-size="{
                                    width: 400,
                                    height: 400
                                }"
                                image-restriction="stencil"
                            />
                            <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex justify-center pb-4 pt-4">
                                <v-btn @click="cropImage" color="success">
                                    <v-icon>mdi-crop</v-icon>
                                    Recortar
                                </v-btn>
                            </div>
                            <div v-else class="d-flex justify-center pb-4">
                                <v-btn @click="cropImage" color="success" width="100%">
                                    <v-icon>mdi-crop</v-icon>
                                    Recortar
                                </v-btn>
                            </div>
                        </template>
                    </template>
                    <template v-if="cameraAtiva">
                        <div v-if="$vuetify.breakpoint.smAndUp" class="mt-4">
                            <video v-show="!fotoTirada" ref="camera" :width="450" :height="337.5" autoplay></video>
                            <div class="d-flex flex-row justify-center align-center">
                                <v-btn class="mt-2 mb-4 mr-2" v-if="cameraAtiva" color="success" @click="tirarFoto">
                                    <v-icon>mdi-camera</v-icon>
                                    Capturar Imagem
                                </v-btn>
                                <canvas v-show="false" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas>
                            </div>
                        </div>
                        <div v-else class="mb-4 mt-4">
                            <video v-show="!fotoTirada" ref="camera" Width="100%" autoplay></video>
                            <v-btn class="mt-2 mb-2" v-if="cameraAtiva" color="success" width="100%" @click="tirarFoto">
                                <div class="d-flex align-center">
                                    <v-icon>mdi-camera</v-icon>
                                </div>
                                <div class="flex-grow-1 text-center">
                                    Capturar Imagem
                                </div>
                            </v-btn>
                            <canvas v-show="false" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas>
                        </div>
                        <template v-if="fotoTirada && showCropper && !cameraAtiva">
                            <cropper
                                ref="cropper"
                                :src="cropperImage"
                                stencil-component="circle-stencil"
                                :stencil-props="{
                                    handlers: {},
                                    movable: false,
                                    resizable: false,
                                }"
                                :stencil-size="{
                                    width: 280,
                                    height: 280
                                }"
                                image-restriction="stencil"
                            />
                            <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex justify-center pb-4 pt-4">
                                <v-btn @click="cropImage" color="success">
                                    <v-icon>mdi-crop</v-icon>
                                    Recortar
                                </v-btn>
                            </div>
                            <div v-else class="d-flex justify-center pb-4">
                                <v-btn @click="cropImage" color="success" width="100%">
                                    <v-icon>mdi-crop</v-icon>
                                    Recortar
                                </v-btn>
                            </div>
                        </template>
                    </template>
                    <v-divider></v-divider>
                    <v-row class="d-flex flex-row justify-center align-center pt-2">
                        <v-col v-if="$vuetify.breakpoint.smAndUp" cols="auto" class="d-flex">
                            <v-btn color="warning" @click="apagaImagem" class="mr-2">
                                <v-icon>mdi-image-off</v-icon>
                                Sem Imagem
                            </v-btn>
                            <v-btn color="error" @click="cancelar" class="mr-2">
                                <v-icon>mdi-close-circle-outline</v-icon>
                                Cancelar
                            </v-btn>
                            <v-btn color="success" @click="salvar" :loading="carregando" :disabled="carregando || !(arquivoModal || urlModal) || showCropper">
                                <v-icon>mdi-check-circle-outline</v-icon>
                                Salvar
                            </v-btn>
                        </v-col>
                        <v-col v-else cols="12">
                            <v-btn color="warning" @click="apagaImagem" width="100%" class="mb-2">
                                <div class="d-flex align-center">
                                    <v-icon>mdi-image-off</v-icon>
                                </div>
                                <div class="flex-grow-1 text-center">
                                    Sem Imagem
                                </div>
                            </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="cancelar" width="100%" class="mb-2">
                            <div class="d-flex align-center">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </div>
                            <div class="flex-grow-1 text-center">
                                Cancelar
                            </div>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="salvar" width="100%" class="mb-2" :loading="carregando" :disabled="carregando || !(arquivoModal || urlModal) || showCropper">
                            <div class="d-flex align-center">
                                <v-icon>mdi-check-circle-outline</v-icon>
                            </div>
                            <div class="flex-grow-1 text-center">
                                Salvar
                            </div>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { MensagemMixin } from '../../helpers/MensagemHelper';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.bubble.css';

export default Vue.component('soi-avatar-input', {
  components: {
    Cropper,
  },
  data() {
    return {
      mostrarModal: false,
      carregando: false,
      cameraAtiva: false,
      fotoTirada: false,
      tirandoFoto: false,
      mostrarErroWebcam: false,
      arquivoModal: '',
      fotoModal: '',
      urlImagem: '',
      arquivo: '',
      urlModal: '',
      cropperImage: null,
      showCropper: false,
    };
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: 'mdi-account',
    },
    value: {},
    fotoKey: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    fotoKey() {
      if (this.fotoKey !== null && this.fotoKey !== '') {
        this.urlImagem = `https://medsystemcloud-new.s3.us-west-2.amazonaws.com/${
          this.fotoKey}`;
      }
    },
  },
  methods: {
    cropImage() {
      const result = this.$refs.cropper.getResult();
      this.urlModal = result.canvas.toDataURL('image/png');
      this.arquivoModal = result.canvas.toBlob((blob) => {
        this.arquivoModal = blob;
      });
      this.showCropper = false;
    },
    apagaImagem() {
      const inputFile = document.getElementById('inputFile') as HTMLInputElement;
      inputFile.value = '';
      this.fecharModal();
      this.cropperImage = '';
      this.urlImagem = '';
      this.urlModal = '';
      this.arquivo = '';
      this.arquivoModal = '';
      this.$emit('input', this.arquivo);
      this.$emit('update:fotoKey', this.urlImagem);
      this.$emit('apagarFoto');
      this.pararCamera();
    },

    async alteraArquivoModal(e: any) {
      const novoArquivo = await e.target.files[0];

      this.arquivoModal = novoArquivo;
      this.urlModal = URL.createObjectURL(this.arquivoModal);
      this.cropperImage = this.urlModal;
      this.showCropper = true;

      this.pararCamera();
    },

    checarWebcam() {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => {
          this.iniciarCamera();
        })
        .catch(() => {
          MensagemMixin(5000, 'warning', 'Não há nenhum dispositivo de captura de imagens disponível no momento.');
          this.mostrarErroWebcam = true;
        });
    },
    async iniciarCamera() {
      this.cameraAtiva = true;

      const constraints: MediaStreamConstraints = {
        audio: false,
        video: true,
      };

      await navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          this.$refs.camera.srcObject = stream;
        })
        .catch(async (error) => {
          this.cameraAtiva = false;
        });
    },
    pararCamera() {
      if (this.cameraAtiva) {
        const tracks = this.$refs.camera.srcObject.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
      this.cameraAtiva = false;
    },

    tirarFoto() {
      const FLASH_TIMEOUT = 50;

      setTimeout(() => {}, FLASH_TIMEOUT);

      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);

      this.urlModal = this.$refs.canvas.toDataURL('image/png');
      this.arquivoModal = this.$refs.canvas.toBlob((blob) => {
        this.arquivoModal = blob;
      });
      this.cropperImage = this.urlModal;
      this.pararCamera();

      this.fotoTirada = true;
      this.showCropper = true;
    },

    async salvar() {
      this.pararCamera();
      this.arquivo = await this.arquivoModal;
      this.urlImagem = await this.urlModal;
      this.$emit('input', this.arquivo);
      this.fecharModal();
    },

    cancelar() {
      const inputFile = document.getElementById('inputFile') as HTMLInputElement;
      inputFile.value = '';
      this.pararCamera();
      this.fecharModal();
      this.showCropper = false;
    },

    abrirModal() {
      this.urlModal = '';
      this.arquivoModal = '';
      this.mostrarModal = true;
    },

    fecharModal() {
      this.mostrarModal = false;
      this.pararCamera();
    },
  },
});
</script>
<style scoped>
.video {
    overflow: hidden;
}
.avatar:hover {
    cursor: pointer;
    transition: 300ms;
    transform: scale(1.01);
    border: solid 1px;
    box-shadow: 0 0 0.2em rgb(137, 137, 137);
}
.button-container {
    position: relative;
    display: inline-block;
    border-radius: 10px;
}

.button-container input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    top: 0;
    right: 0;
    opacity: 0;
}

.imagem {
    width: 100%;
    height: auto;
    max-height: 300px;
    max-width: 300px;
}

.v-avatar {
    display: flow-root !important;
}
</style>
