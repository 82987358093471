<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="4 6 15 15" fill="none">
        <path d="M17.3158 12.6317L15.6241 14.6054L17.3158 16.5791" stroke="#10A860" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14.2706 12.6317L12.5789 14.6054L14.2706 16.5791" stroke="#10A860" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.component('soi-icon-arrow-page-left', {

});
</script>
