export default class UsuarioDTO {
    public id: string;

    public nomeCompleto: string;

    public email: string;

    public role: string;

    constructor() {
      this.id = '';
      this.nomeCompleto = '';
      this.email = '';
      this.role = '';
    }
}
