<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
		<path fill="currentColor" d="M13 5h6a1 1 0 0 1 1 1v2h-8V6a1 1 0 0 1 1-1m-3 1v2H7.5A4.5 4.5 0 0 0 3 12.5v11A4.5 4.5 0 0 0 7.5
		28h17a4.5 4.5 0 0 0 4.5-4.5v-11A4.5 4.5 0 0 0 24.5 8H22V6a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3m6 8a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1" />
	</svg>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.component('soi-icon-briefcase', {

});
</script>
