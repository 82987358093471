import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mt-2 mb-2",attrs:{"outlined":""}},[_c(VCardText,[_c(VCardTitle,[_vm._v("Regras de Obrigatoriedade De Campos")]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","xs":"12"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v("Habilitar/Desabilitar Campos Obrigatórios")]),_c(VCardText,[_c(VRow,[_c('soi-switch',{attrs:{"name":'Habilitar Obrigatoriedade CPF',"label":_vm.pacoteAtivo.habilitarCpf ? 'CPF obrigatório no cadastro' : 'CPF não obrigatório no cadastro',"disabled":_vm.disabled},model:{value:(_vm.pacoteAtivo.habilitarCpf),callback:function ($$v) {_vm.$set(_vm.pacoteAtivo, "habilitarCpf", $$v)},expression:"pacoteAtivo.habilitarCpf"}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }