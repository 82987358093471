import Contato from '../objetosDeValor/Contato';
import Endereco from '../objetosDeValor/Endereco';

export default class ClinicaConfirmarAgendamento {
    public razaoSocial: string;

    public endereco!: Endereco;

    public contato!: Contato;


    constructor() {
      this.razaoSocial = '';
      this.endereco = new Endereco();
      this.contato = new Contato();
    }
}
