export default class Plano {
    public id: number;

    public nome: string;

    public descricao: string;

    public convenioID: number;

    constructor() {
      this.id = 0;
      this.nome = '';
      this.descricao = '';
      this.convenioID = 0;
    }
}
