<template>
  <div class="m-0">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-chip small label dark class="mb-1 d-flex justify-center"
          :style="{ 'background-color': coresSituacao[situacaoAgendamento] }" @click.stop="clickEvento()" v-bind="attrs"
          v-on="on">
          {{ situacoes[situacaoAgendamento] === 'EmEspera'
            ? 'Em Espera'
            : situacoes[situacaoAgendamento] === 'EmAndamento'
              ? 'Em Andamento'
              : situacoes[situacaoAgendamento] }}
          <v-icon v-if="situacoes[situacaoAgendamento] === 'Cancelado'"
            style="position: absolute; right: 8px;" color="#424242"
            @click.stop="clickIconVisualizacao">
            mdi-eye
          </v-icon>
        </v-chip>
      </template>
      <span>{{ tipoAgendamento === 'Consulta' ? mostrarProximaSituacaoAgendamento :
        mostrarProximaSituacaoAgendamentoCompromisso }}</span>
    </v-tooltip>
    <v-dialog v-model="confirmaCancelamentoAgendamento" max-width="800" persistent>
      <v-card>
        <v-card-title v-if="!visualizarMotivoCancelamento" class="mr-3">Para concluir o cancelamento, é obrigatório
          informar
          o
          motivo.
          Por favor, insira o motivo do
          cancelamento.
        </v-card-title>
        <v-card-title v-else class="mr-3">Motivo do cancelamento:</v-card-title>
        <v-card-text>
          <v-textarea :disabled="visualizarMotivoCancelamento" class="mr-3" v-model="motivoCancelamento"
            label="Motivo do cancelamento" placeholder="Digite o motivo do cancelamento aqui..." outlined
            rows="4"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="fecharDialogo">{{ visualizarMotivoCancelamento ? 'Fechar' : 'Cancelar' }}</v-btn>
          <v-btn v-if="!visualizarMotivoCancelamento" color="primary" @click="salvarObservacao"
            :disabled="motivoCancelamento === ''">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { CORES_SITUACAO_AGENDAMENTO } from '@/constants/constantSituacaoAgendamento';
import { AlertaPerguntaSimOuNaoBooleano } from '@/helpers/MensagemHelper';
import { SituacaoAgendamento } from '@/models/enums/EnumSituacaoAgendamento';
import { TipoAgendamento } from '@/models/enums/EnumTipoAgendamento';
import { AgendamentoService } from '@/services/AgendamentoService';
import Vue from 'vue';



export default Vue.extend({
  name: 'soi-atualiza-situacao-agendamento',
  props: {
    situacao: {
      type: String,
      required: true,
    },
    tipoAgendamento: {
      type: String,
      required: true,
    },
    agendamentoId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      coresSituacao: CORES_SITUACAO_AGENDAMENTO,
      situacoes: Object.values(SituacaoAgendamento),
      proximaSituacaoAgendamento: [1, 2, 3, 4, 5, 0],
      proximaSituacaoAgendamentoCompromisso: [3, 2, 3, 4, 5, 0],
      situacaoAgendamento: this.situacao,
      confirmaCancelamentoAgendamento: false,
      motivoCancelamento: '',
      visualizarMotivoCancelamento: false,
      agendamentoService: new AgendamentoService(),
    };
  },

  computed: {
    proximaSituacao() {
      const situacaoMap = {
        Marcado: 0,
        EmEspera: 1,
        EmAndamento: 2,
        Faltou: 3,
        Finalizado: 4,
        Cancelado: 5,
      };

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.situacaoAgendamento = situacaoMap[this.situacaoAgendamento] ?? this.situacaoAgendamento;
      return this.proximaSituacaoAgendamento[this.situacaoAgendamento];
    },

    proximaSituacaoCompromisso() {
      const situacaoMap = {
        Marcado: 0,
        Faltou: 3,
        Finalizado: 4,
        Cancelado: 5,
      };
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.situacaoAgendamento = situacaoMap[this.situacaoAgendamento] ?? this.situacaoAgendamento;
      return this.proximaSituacaoAgendamentoCompromisso[this.situacaoAgendamento];
    },

    mostrarProximaSituacaoAgendamento() {
      const situacoesFormatadas = {
        EmEspera: 'Em Espera',
        EmAndamento: 'Em Andamento',
      };

      const situacaoFormatada = situacoesFormatadas[this.situacoes[this.proximaSituacao]]
        || this.situacoes[this.proximaSituacao];

      if (this.situacao === 'Cancelado') {
        return 'O agendamento foi cancelado e, por isso, não pode ser editado. Caso queira verificar o motivo do cancelamento, clique no ícone de visualização para conferir os detalhes.';
      }
      return `Clique para alterar a situação para: ${situacaoFormatada}`;
    },

    mostrarProximaSituacaoAgendamentoCompromisso() {
      return `Clique para alterar a situação para: ${this.situacoes[this.proximaSituacaoCompromisso]}`;
    },
  },

  methods: {
    async clickEvento() {
      if (this.tipoAgendamento === 'Consulta') {
        const proximaSituacaoEnum = SituacaoAgendamento[this.proximaSituacao];
        const confirmarCancelamento = proximaSituacaoEnum === 'Cancelado'
          ? await AlertaPerguntaSimOuNaoBooleano(
            'Deseja realmente cancelar esse agendamento?',
            'O agendamento será cancelado, essa ação é irreversível!',
          )
          : true;

        if (confirmarCancelamento) {
          if (proximaSituacaoEnum === 'Cancelado') {
            this.confirmaCancelamentoAgendamento = true;
            return;
          }
          this.$emit('clickSituacaoAgendamento', {
            situacao: this.situacao,
            proximaSituacao: this.proximaSituacao,
            tipoAgendamento: this.tipoAgendamento,
          });
        }
      } else {
        this.$emit('clickSituacaoAgendamento', {
          situacao: this.situacao,
          proximaSituacaoCompromisso: this.proximaSituacaoCompromisso,
          tipoAgendamento: this.tipoAgendamento,
        });
      }
    },
    fecharDialogo() {
      this.confirmaCancelamentoAgendamento = false;
      this.motivoCancelamento = '';
      this.visualizarMotivoCancelamento = false;
    },
    clickIconVisualizacao() {
      this.visualizarMotivoCancelamento = true;
      this.confirmaCancelamentoAgendamento = true;

      this.agendamentoService
        .buscarAgendamentoPorId(this.agendamentoId)
        .then((res) => {
          this.motivoCancelamento = res.data.motivoCancelamentoAgendamento;
        });
    },
    salvarObservacao() {
      if (this.motivoCancelamento.trim()) {
        this.$emit('clickSituacaoAgendamento', {
          situacao: this.situacao,
          proximaSituacao: this.proximaSituacao,
          tipoAgendamento: this.tipoAgendamento,
          motivoCancelamento: this.motivoCancelamento,
        });
        this.fecharDialogo();
      }
    },
  },
});
</script>
