<template>
    <v-card outlined class="mt-2 mb-2">
        <v-card-title>Módulos Complementares</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" xs="12">
                    <v-card outlined>
                        <v-card-title>Recursos Adicionais</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="5" sm="12">
                                    <soi-switch :name="'Habilitar triagem'"
                                        :label="pacoteAtivado.triagemAtiva ? 'Triagem Habilitada' : 'Habilitar triagem'"
                                        v-model="pacoteAtivado.triagemAtiva" :disabled="disabled">
                                    </soi-switch>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
import ModulosComplementares from '@/models/objetosDeValor/ModulosComplementares';
import Vue from 'vue';

export default Vue.component('soi-modulos-complementares', {
  props: {
    value: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      pacoteAtivado: new ModulosComplementares(),

    };
  },
  watch: {
    value() {
      this.pacoteAtivado = this.value;
    },

    pacoteAtivado() {
      this.$emit('input', this.pacoteAtivado);
    },

  },
});
</script>
