<template>
  <v-navigation-drawer app class="sidebar" v-model="drawer" permanent absolute height="100%"
    :width="miniSidebar ? '114' : '305'" :mini-variant="miniSidebar" :mini-variant-width="miniSidebar ? '114' : '305'">
    <div class="d-flex flex-column justify-center align-center">
      <!-- Logo e menu aqui -->
      <div class="d-flex flex-row align-center">
        <div class="logo-mini" v-show="miniSidebar">
          <v-img src="../assets/images/favicon-medsystemcloud.png" @click="alterarSidebar" />
        </div>
        <div class="logo-full" v-show="!miniSidebar">
          <v-img src="../assets/images/logo-sidebar.png" />
          <v-icon size="16" @click="alterarSidebar">$menu</v-icon>
        </div>
      </div>
      <v-divider class="mx-auto sidebar-divider" :class="miniSidebar ? 'sidebar-divider-mini' : ''"></v-divider>
    </div>
    <div class="navbar sidebar-content" ref="sidebarContent">
      <v-list nav dense class="navbar-list-full">
        <template v-for="(item, index) in items">
          <template v-if="temPermissaoNoItem(item)">
            <div :key="index" v-show="verificaAcesso(item.roles, item.permissions)">
              <v-list-item :to="item.children.length !== 0 ? '' : item.path" @click.stop="alterarSidebar"
                :disabled="desabilitaModulosComplementares(item.roles, item.permissions)"
                :class="[ !miniSidebar ? 'navbar-item-full' : 'navbar-item-mini',
                         { 'v-list-item--active': isActive(item) }]">
                <v-icon :size="!miniSidebar ? '20' : '30'" class="navbar-item-icon">{{ item.icon }}</v-icon>
                <v-list-item-title class="navbar-item-title">
                  {{ item.label }}
                </v-list-item-title>
              </v-list-item>
              <div v-for="child in item.children" :key="child.name" :class="['navbar-child', { 'mini-mode': miniSidebar }]">
                <v-list-item :to="child.path" v-show="verificaAcesso(child.roles, child.permissions) && !miniSidebar"
                  :class="[ !miniSidebar ? 'navbar-child-full' : 'navbar-child-mini', { 'v-list-item--active': isActive(child) }]">
                  <v-icon size="12" class="navbar-child-icon"> $arrowRight </v-icon>
                  <v-tooltip v-if="naoTemPermissaoTriagem(child)" bottom>
                    <template #activator="{ on, attrs }">
                      <v-list-item-title v-bind="attrs" v-on="on"
                        :class="['navbar-child-title', { disabled: naoTemPermissaoTriagem(child) }]">
                        {{ child.label }}
                      </v-list-item-title>
                    </template>
                    <span>
                      Entre em contato pelo telefone 0800 707 5900 para<br>
                      adquirir essa funcionalidade
                    </span>
                  </v-tooltip>
                  <v-list-item-title v-else :class="['navbar-child-title', { 'disabled': naoTemPermissaoTriagem(child) }]">
                    {{ child.label }}
                  </v-list-item-title>

                </v-list-item>
              </div>
            </div>
          </template>
        </template>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';
import NavigationHelper from '../helpers/NavigationHelper';
import sidebarItems from '../router/sidebar';
import { decode, getToken } from '../utils/Token';
import AuthUserModel from '../models/Auth/AuthUserModel';
import PermissoesPadrao from '@/constants/enums/PermissoesPadrao';

export default Vue.extend({
  name: 'sidebar',
  data() {
    return {
      miniSidebar: false,
      drawer: true,
      get items() {
        return sidebarItems;
      },
    };
  },
  watch: {
    value() {
      this.miniSidebar = this.value;
    },
  },
  methods: {
    naoTemPermissaoTriagem(child: any): boolean {
      const sessaoUsuario = decode(getToken());
      const triagemNome = child.name === 'triagens';
      const temPermissao = sessaoUsuario.permissions.includes(PermissoesPadrao.AcessoTriagem);
      return triagemNome && !temPermissao;
    },

    alterarSidebar() {
      this.miniSidebar = !this.miniSidebar;
      this.$emit('miniSidebar', this.miniSidebar);
    },

    verificaAcesso(roles: string[], permissions: string[]) {
      let sessaoUsuario: AuthUserModel = new AuthUserModel();
      sessaoUsuario = decode(getToken());
      return roles.includes(sessaoUsuario.role);
    },

    temPermissaoNoItem(item) {
      if ((item.permissions && item.permissions.length > 0) || item.children) {
        return this.verificaAcesso(item.roles, item.permissions);
      } if (item.children && item.children.length > 0) {
        return item.children.some((child) => this.temPermissaoNoItem(child));
      }
      return false;
    },

    desabilitaModulosComplementares(roles, permissions) {
      const sessaoUsuario = decode(getToken());
      const temRoleValido = roles.includes(sessaoUsuario.role);
      const temPermissoesValidas = permissions.every((perm) => sessaoUsuario.permissions.includes(perm));
      return !(temRoleValido && temPermissoesValidas);
    },

    selecionarRota(nomeRota: string) {
      NavigationHelper.navigate(nomeRota);
    },

    isActive(route: any): boolean {
      if (this.$route.name === route.name) return true;
      if (this.miniSidebar && route.children && route.children.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const child of route.children) {
          if (child.name === this.$route.name) return true;
        }
        return route.children.some((child) => child.relatedRoutes && child.relatedRoutes.includes(this.$route.name));
      }
      if (route.relatedRoutes) {
        return route.relatedRoutes.some((name: string) => name === this.$route.name);
      }
      return false;
    },
  },
});
</script>

<style>
.sidebar {
  background: #10a860 !important;
  border: none;
}

.sidebar-divider {
  border-color: rgba(255, 255, 255, 0.2) !important;
  width: 17rem;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
}

.sidebar-divider-mini {
  border-color: rgba(255, 255, 255, 0.2) !important;
  width: 4.9rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.logo-full {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 172px;
  max-height: 36px;
  margin-top: 24px;
  margin-left: 42px;
}

.logo-full .v-icon {
  margin-left: 34px;
  margin-right: 40px;
  color: #fff;
}

.logo-mini {
  margin-top: 1rem;
  cursor: pointer;
}

.v-navigation-drawer__border {
  width: 0;
}

.navbar-list-full .navbar-item-full {
  padding-left: 42px;
  margin-bottom: 16px;
}

.navbar-list-full .navbar-child-full {
  padding-left: 72px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.v-list-item--dense,
.v-list--dense .navbar-item-full {
  height: 26px !important;
  margin-left: 1rem;
  margin-right: 1rem;
}

.navbar .navbar-item-title {
  font-weight: 700 !important;
  font-size: 1rem !important;
  line-height: 22px;
  letter-spacing: 2%;
  color: #ffca5a;
  text-transform: uppercase;
}

.navbar .navbar-item-icon {
  color: #ffca5a !important;
}


.navbar .navbar-child-icon {
  color: #fff !important;
}

.navbar-item-full {
  margin-top: 0.5rem !important;
}

.navbar-item-mini {
  margin-top: 0.5rem !important;
  cursor: pointer !important;
  margin-left: 1rem;
  margin-right: 1rem;
}

.sidebar-content {
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}
</style>
