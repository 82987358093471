export default class Prescricao {
    public id: number;

    public dataPrescricao: string;

    public listaMedicamentos: [];

    public prescricaoCompleta: string;

    public observacao: string;

    constructor() {
      this.id = 0;
      this.dataPrescricao = '0001-01-01T00:00:00';
      this.listaMedicamentos = [];
      this.prescricaoCompleta = '';
      this.observacao = '';
    }
}
