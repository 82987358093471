enum Role {
    Host = 'Host',
    Admin = 'Admin',
    Administrador = 'Administrador',
    Medico = 'Medico',
    Secretario = 'Secretario',
    Enfermeiro = 'Enfermeiro',
    TecEnfermagem = 'TecEnfermagem',
}

export default Role;
