import Contato from '../objetosDeValor/Contato';
import Endereco from '../objetosDeValor/Endereco';

export enum Sexo{
    Masculino, Feminino
}

export enum EstadoCivil{
    Solteiro, Casado, Viuvo, Divorciado
}

export enum Escolaridade{
    SemInstrucao, EnsinoFundamental, EnsinoMedio, Graduacao, PosGraduacao, Mestrado, Doutorado, PHD
}

export enum Raca{
    Amarela, Branca, Preta, Parda, Indigena
}

export default class Pacientes {
    public id: number;

    public nome: string;

    public nomeSocial: string;

    public nomeExibirSocial: string

    public usarNomeSocialComoPrincipal: boolean;

    public sobrenome: string;

    public cpf?: string;

    public rg: string;

    public file?: File;

    public foto: string;

    public dataNascimento: string;

    public sexo?: Sexo;

    public estadoCivil?: EstadoCivil;

    public escolaridade?: Escolaridade;

    public raca?: Raca;

    public endereco!: Endereco;

    public contato!: Contato;

    public nomePai: string;

    public nomeMae: string;

    public listaConvenios: string;

    public ativo: boolean;

    nomeCompleto: string;

    public observacaoPaciente: string

    constructor() {
      this.id = 0;
      this.nome = '';
      this.nomeSocial = '';
      this.nomeExibirSocial = '';
      this.usarNomeSocialComoPrincipal = false;
      this.sobrenome = '';
      this.rg = '';
      this.foto = '';
      this.dataNascimento = '0001-01-01T00:00:00';
      this.endereco = new Endereco();
      this.contato = new Contato();
      this.nomePai = '';
      this.nomeMae = '';
      this.listaConvenios = '';
      this.ativo = true;
      this.observacaoPaciente = '';
    }
}




