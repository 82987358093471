<template>
  <v-dialog v-model="modalAberto" transition="dialog-bottom-transition" max-width="1200" persistent>
    <v-card class="mx-auto" :loading="carregando ? 'success' : null" :disabled="carregando">
      <v-toolbar flat outlined>
        <v-toolbar-title><v-icon color="success" left size="x-large">mdi-bookmark-plus-outline</v-icon>{{ titulo
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="mx-auto" icon @click="fecharModal()"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <validation-observer ref="observer" v-slot="{ }">
        <form @submit.prevent="salvar">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-btn-toggle dense v-model="agendamento.tipoAgendamento" group class="mb-n6 mt-n6"
                  @change="mudarTipoAgendamento">
                  <v-btn class="buttons-toggle" small :value="tipoAgendamento.Consulta"
                    :disabled="editarAgendamento || agendamento.tipoAgendamento === tipoAgendamento.Consulta">
                    Consulta
                  </v-btn>

                  <v-btn v-if="!agendamentoRetorno && !preConsultaPaciente" class="buttons-toggle" small
                    :value="tipoAgendamento.Compromisso"
                    :disabled="editarAgendamento || agendamento.tipoAgendamento === tipoAgendamento.Compromisso">
                    Compromisso
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row class="p-10">
              <v-col v-if="agendamento.tipoAgendamento ==
                tipoAgendamento.Consulta &&
                !agendamentoRetorno
              " cols="12" sm="12" md="3">
                <soi-select v-model="agendamento.agendamentoConsulta
                  .tipoConsultaId
                  " label="Tipo de Consulta" :items="options_consulta">
                </soi-select>
              </v-col>
              <v-col v-if="agendamento.tipoAgendamento ===
                tipoAgendamento.Consulta &&
                preConsultaPaciente
              " cols="12" sm="12" md="4">
                <soi-input v-model="agendamento.agendamentoConsulta.paciente
                  .nome
                  " :label="'Nome *'" :name="'Nome'" prepend-icon="mdi-account-plus"
                  :rules="{ required: true }"></soi-input>
              </v-col>
              <v-col v-if="agendamento.tipoAgendamento ===
                tipoAgendamento.Consulta &&
                preConsultaPaciente
              " cols="12" sm="12" md="4">
                <soi-input v-model="agendamento.agendamentoConsulta.paciente
                  .sobrenome
                  " :label="'Sobrenome *'" :name="'Sobrenome'" :rules="{ required: true }"></soi-input>
              </v-col>
              <v-col v-if="agendamento.tipoAgendamento ===
                tipoAgendamento.Consulta &&
                !preConsultaPaciente
              " cols="12" sm="12" :md="agendamentoRetorno || editarAgendamento ? 9 : 8">
                <soi-picker name="pacientes" :value="pacienteSelecionado" :disabled="agendamentoRetorno"
                  :rules="{ required: true }" prepend-icon="mdi-account-search" label="Paciente" icon="mdi-account"
                  title="Buscar Paciente" :headers="colunasPaciente" :items="pacientes" :hint="editarAgendamento || agendamentoRetorno
                    ? ''
                    : 'Caso o paciente não possua cadastro, clique no botão ao lado'
                    " :singleSelect="true" @change="changePaciente" @update="getPacientes"></soi-picker>
              </v-col>
              <v-col v-if="agendamento.tipoAgendamento ==
                tipoAgendamento.Compromisso
              " cols="12" sm="12" md="12">
                <soi-input v-model="agendamento.agendamentoCompromisso.descricao
                  " :label="'Descrição do compromisso *'" :name="'Descrição'" :rules="{ required: true }">
                </soi-input>
              </v-col>
              <v-col v-if="agendamento.tipoAgendamento ==
                tipoAgendamento.Consulta &&
                !agendamentoRetorno &&
                !editarAgendamento
              " cols="12" sm="12" md="1">
                <v-tooltip bottom color="success">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn block color="success" @click="alteraVariavelNovoPaciente()" v-bind="attrs" v-on="on">
                      <v-icon>
                        {{
                          preConsultaPaciente
                            ? 'mdi-account-search'
                            : 'mdi-account-plus'
                        }}
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{
                    preConsultaPaciente
                      ? 'Buscar Paciente'
                      : 'Criar novo paciente'
                  }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                  offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field name="data" color="success" outlined dense v-model="agendamento.dataAgendamento
                      " label="Data *" persistent-hint prepend-icon="mdi-calendar" v-bind="attrs" readonly
                      v-mask="masks.data" @blur="
                        date = parseDate(
                          agendamento.dataAgendamento
                        )
                        " v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker color="success" v-model="date" @change="
                    buscarHorarios(
                      agendamento.agendaId,
                      date
                    )
                    " no-title @input="menu1 = false" locale="pt-BR" :allowed-dates="desabilitarDatas"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <validation-provider v-slot="{ errors }" name="Horário" :rules="{ required: true }">
                  <v-select name="Horário" @input="preencherDuracaoAgendamento()" v-model="agendamento.horaAgendamento"
                    :error-messages="errors" label="Horário *" no-data-text="Configure os horários" :items="horarios"
                    :nullable="false" outlined dense color="success" item-color="success">
                  </v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <validation-provider v-slot="{ errors }" name="Duração" :rules="{ required: true }">
                  <v-text-field label="Duração *" name="Duração" v-model="agendamento.duracaoAgendamento" type="time"
                    dense outlined color="success" :error-messages="errors"></v-text-field>
                </validation-provider>
              </v-col>
              <v-col v-if="!agendamentoRetorno" cols="12"
                md="3" sm="12">
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="verificaEdicao" height="40" block outlined color="grey darken-2" v-bind="attrs"
                      v-on="on">
                      <v-icon left dense :color="corDaSituacaoAgendamento(
                        agendamento.situacaoAgendamento
                      )
                        ">
                        {{
                          iconeDaSituacaoAgendamento(
                            agendamento.situacaoAgendamento
                          )
                        }}
                      </v-icon>
                      <span :class="`${corDaSituacaoAgendamento(
                        agendamento.situacaoAgendamento
                      )}--text`">
                        {{
                          textDaSituacaoAgendamento(
                            agendamento.situacaoAgendamento
                          )
                        }}</span>
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="item in opcoesSituacaoAgendamento" :key="item.value" @click="
                      agendamento.situacaoAgendamento =
                      item.value
                      ">
                      <v-list-item-title :text-color="item.color">
                        <v-icon :color="item.color" left dense>{{ item.icon }}</v-icon>
                        <span :class="`${item.color}--text`">{{ ' ' + item.text }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col v-if="preConsultaPaciente" cols="12" md="3" sm="12">
                <soi-input :name="'Data de Nascimento'" :label="'Data de Nascimento *'" :disabled="disabled"
                  :rules="{ required: true }" v-model="agendamento.agendamentoConsulta.paciente.dataNascimento"
                  type="date" @input="validarDataNascimentoParaDesabilitarTriagem">
                </soi-input>
              </v-col>
              <v-col v-if="agendamento.tipoAgendamento ==
                tipoAgendamento.Consulta &&
                !agendamentoRetorno
              " cols="12" md="4" sm="12">
                <soi-input :label="'CPF'" :name="'CPF'" v-model="cpfPaciente" :disabled="!preConsultaPaciente"
                  :placeholder="'000.000.000-00'" :mask="masks.cpf" :rules="{
                    min: 14,
                    cpf: 'cpf',
                    required: false,
                  }">
                </soi-input>
              </v-col>
              <v-col v-if="agendamento.tipoAgendamento ==
                tipoAgendamento.Consulta &&
                !agendamentoRetorno
              " cols="12" md="5" sm="12">
                <soi-input :label="'Email'" :name="'Email'" v-model="agendamento.agendamentoConsulta.email
                  "></soi-input>
              </v-col>
              <v-col v-if="agendamento.tipoAgendamento ==
                tipoAgendamento.Consulta
              " cols="12" md="3" sm="12">
                <soi-input :label="'Telefone'" :name="'Telefone'" v-model="agendamento.agendamentoConsulta.telefone
                  " :mask="masks.telefone"></soi-input>
              </v-col>
              <v-col v-if="agendamento.tipoAgendamento ==
                tipoAgendamento.Consulta
              " cols="12" :md="agendamento.tipoAgendamento === tipoAgendamento.Consulta && !preConsultaPaciente ? 6 : 4"
                sm="12">
                <soi-select v-model="agendamento.agendamentoConsulta
                  .convenioId
                  " label="Convênio" :items="options_convenio" @input="mudarPlanos">
                </soi-select>
              </v-col>
              <v-col v-if="agendamento.tipoAgendamento ==
                tipoAgendamento.Consulta
              " cols="12" :md="agendamento.tipoAgendamento === tipoAgendamento.Consulta && !preConsultaPaciente ? 6 : 5"
                sm="12">
                <soi-select v-model="agendamento.agendamentoConsulta.planoId
                  " label="Plano" :items="options_plano">
                </soi-select>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center mb-6">
              <v-col v-if="agendamento.tipoAgendamento ==
                tipoAgendamento.Consulta
              " cols="12" md="2" sm="12">
                <soi-switch class="mt-n1" v-model="agendamento.agendamentoConsulta.retorno
                  " :label="'Retorno'" :disabled="agendamentoRetorno"></soi-switch>
              </v-col>
              <v-col v-if="agendamento.tipoAgendamento ==
                tipoAgendamento.Consulta
              " cols="12" md="4" sm="12">
                <soi-switch class="mt-n1" v-model="agendamento.agendamentoConfirmado"
                  :label="'Agendamento confirmado'"></soi-switch>
              </v-col>
            </v-row>
            <v-row class="p-10">
              <v-col cols="12" sm="12" md="12">
                <v-textarea label="Observação" outlined rows="1" color="success"
                  v-model="agendamento.observacaoAgendamento">
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="exibirTriagemRetorno" id="triagem" color="primary" :loading="carregando"
              :disabled="desabilitarBotaoTriagem || agendamento.tipoAgendamento === tipoAgendamento.Compromisso || desabilitaModulosComplementares()"
              v-show="!desabilitaModulosComplementares()" @click="realizarTriagemParaForm()">
              <v-icon left>mdi-checkbox-marked-circle</v-icon>
              Realizar Triagem
            </v-btn>
            <v-btn dense color="warning" @click="fecharModal()" :loading="carregando">
              <v-icon left>mdi-keyboard-return</v-icon> Voltar
            </v-btn>
            <v-btn id="salvar" type="input" color="success" :loading="carregando" :disabled="carregando">
              <v-icon left>mdi-checkbox-marked-circle</v-icon>
              Salvar
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>

    <v-dialog v-model="modalTriagens" :max-width="isMobile ? '100%' : '1600'">
      <v-card class="text-center mx-auto">
        <v-toolbar flat outlined>
          <v-toolbar-title><v-icon color="success" left size="x-large">mdi-heart-pulse</v-icon>Realizar
            Triagem</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="fecharModalTriagem()"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>

        <soi-dados-padrao-triagem ref="modalTriagem" :mostrarBotoes="true" :modalTriagem="modalTriagens"
          :rotaModalAgendamento="'ModalAgendamento'" @triagemSalva="fecharModalTriagem"
          @atualizaCalendario="$emit('atualizaCalendario');"></soi-dados-padrao-triagem>
      </v-card>

    </v-dialog>

  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  AlertaSimples,
  AlertaSimplesErro,
  MensagemMixin,
} from '../../helpers/MensagemHelper';
import Agendamento from '../../models/agendamento/Agendamento';
import { AgendamentoService } from '../../services/AgendamentoService';
import { TipoConsultaService } from '../../services/TipoConsultaService';
import { ConvenioService } from '../../services/ConveioService';
import { PlanoService } from '../../services/PlanoService';
import { PacientesService } from '../../services/PacientesService';
import MaskHelper from '../../helpers/MaskHelper';
import DateHelper from '../../helpers/DateHelper';
import { TipoAgendamento } from '../../models/enums/EnumTipoAgendamento';
import { SituacaoAgendamento } from '../../models/enums/EnumSituacaoAgendamento';
import AgendamentoConsulta from '../../models/agendamento/AgendamentoConsulta';
import AgendamentoCompromisso from '../../models/agendamento/AgendamentoCompromisso';
import { BloqueioHorarioAgendaService } from '../../services/BloqueioHorarioAgendaService';
import { FeriadoAgendaService } from '../../services/FeriadoAgendaService';
import BloqueioHorarioAgenda from '../../models/agenda/BloqueioHorarioAgenda';
import FeriadoAgenda from '../../models/agenda/FeriadoAgenda';
import Agenda from '../../models/agenda/Agenda';
import Triagem from '../../models/triagem/Triagem';
import Pacientes from '../../models/pacientes/Pacientes';
import { decode, getToken } from '@/utils/Token';
import PermissoesPadrao from '@/constants/enums/PermissoesPadrao';
import { TriagemService } from '@/services/TriagemService';
import { AgendaService } from '@/services/AgendaService';
import { IHorarioReservado } from '@/models/agenda/IHorarioReservado';

export default Vue.component('soi-modalAgendamento', {
  name: 'soi-modalAgendamento',
  components: {
  },
  data() {
    return {
      // data
      date: DateHelper.formataParaAnoMesDia(
        new Date(Date.now()).toISOString().substr(0, 10),
      ),
      dateFormatted: DateHelper.formataParaDiaMesAno(
        new Date(Date.now()).toISOString().substr(0, 10),
      ),
      menu1: false,
      menu2: false,
      modalAberto: false,
      modalTriagens: false,

      // triagem aqui
      triagem: new Triagem(),
      paciente: new Pacientes(),
      dataNascimentoValido: false,
      desabilitarBotaoTriagem: false,

      idAgenda: 1,
      agenda: new Agenda(),
      agendamento: {
        agendaId: 0,
        tipoAgendamento: null,
        dataAgendamento: '',
        horaAgendamento: '',
        agendamentoConfirmado: false,
        duracaoAgendamento: '',
        observacaoAgendamento: '',
        situacaoAgendamento: 0,
        agendamentoConsulta: {
          tipoConsultaId: null,
          email: '',
          telefone: '',
          retorno: false,
          pacienteId: '',
          paciente: {
            nome: '',
            sobrenome: '',
            dataNascimento: '',
            contato: {
              emailPrincipal: '',
              telefonePrincipal: '',
            },
          },
          convenioId: null,
          planoId: null,
        },
        agendamentoCompromisso: {
          descricao: '',
        },
      },
      editarAgendamento: false,

      // Pre-Consulta
      corFundo: 'success',
      corBotao: 'success',
      preConsultaPaciente: false,

      // AgendamentoRetorno
      agendamentoRetorno: false,
      editarAgendamentoRetorno: '',

      // services
      agendamentoService: new AgendamentoService(),
      tipoConsultaService: new TipoConsultaService(),
      convenioService: new ConvenioService(),
      planoService: new PlanoService(),
      pacienteService: new PacientesService(),
      bloqueiosHorariosService: new BloqueioHorarioAgendaService(),
      feriadosService: new FeriadoAgendaService(),
      triagemService: new TriagemService(),
      agendaService: new AgendaService(),

      tipoAgendamento: TipoAgendamento,
      carregando: false,
      masks: MaskHelper,
      opcoesSituacaoAgendamento: [
        {
          value: 0,
          text: 'Marcado',
          icon: 'mdi-calendar-check',
          color: '#84b6f4',
        },
        {
          value: 1,
          text: 'Em espera',
          icon: 'mdi-clock-alert-outline',
          color: '#77dd77',
        },
        {
          value: 2,
          text: 'Em andamento',
          icon: 'mdi-progress-check',
          color: '#ffda9e',
        },
        {
          value: 3,
          text: 'Faltou',
          icon: 'mdi-close-octagon-outline',
          color: '#ff6961',
        },
        {
          value: 4,
          text: 'Finalizado',
          icon: 'mdi-check-circle-outline',
          color: '#c5c6c8',
        },
        {
          value: 5,
          text: 'Cancelado',
          icon: 'mdi-cancel',
          color: '#ffe180',
        },
      ],
      options_convenio: [],
      options_plano: [],
      options_consulta: [],
      horarios: [] as { text: string; value: string }[],
      horariosAgenda: [],
      datasBloqueadas: [] as BloqueioHorarioAgenda [],
      feriados: [] as FeriadoAgenda [],
      minDate: new Date(Date.now()),
      tempListaHorarios: [],
      idPaciente: 0,
      idAgendamento: 0,
      cpfPaciente: '',
      colunasPaciente: [
        {
          text: 'Id',
          align: 'start',
          value: 'id',
          filterable: true,
          sortable: true,
        },
        {
          text: 'CPF',
          value: 'cpf',
          filterable: true,
          sortable: true,
        },
        {
          text: 'Nome',
          value: 'nome',
          filterable: true,
          sortable: true,
        },
        {
          text: 'Sobrenome',
          value: 'sobrenome',
          filterable: true,
          sortable: true,
        },
        {
          text: 'Nome Social',
          value: 'nomeExibirSocial',
          filterable: true,
          sortable: true,
        },
        {
          text: 'Data de Nascimento',
          value: 'dataNascimento',
          filterable: true,
        },
        {
          text: 'Idade',
          value: 'idade',
        },
      ],
      pacientes: [],
      pacienteSelecionado: '',
      idPacienteSelecionado: 0,
      SESSAO_PACIENTE_ARMAZENAMENTO: 'agendamento_paciente_session_storage',
    };
  },
  watch: {
    date() {
      this.agendamento.dataAgendamento = DateHelper.formataParaDiaMesAno(this.date);
    },
    'agendamento.tipoAgendamento': function () {
      if (this.agendamento.tipoAgendamento === TipoAgendamento.Consulta) {
        this.opcoesSituacaoAgendamento = [
          {
            value: 0,
            text: 'Marcado',
            icon: 'mdi-calendar-check',
            color: '#84b6f4',
          },
          {
            value: 1,
            text: 'Em espera',
            icon: 'mdi-clock-alert-outline',
            color: '#77dd77',
          },
          {
            value: 2,
            text: 'Em andamento',
            icon: 'mdi-progress-check',
            color: '#FFA500',
          },
          {
            value: 3,
            text: 'Faltou',
            icon: 'mdi-close-octagon-outline',
            color: '#ff6961',
          },
          {
            value: 4,
            text: 'Finalizado',
            icon: 'mdi-check-circle-outline',
            color: '#c5c6c8',
          },
          {
            value: 5,
            text: 'Cancelado',
            icon: 'mdi-cancel',
            color: '#FFD700',
          },
        ];
      } else {
        this.opcoesSituacaoAgendamento = [
          {
            value: 0,
            text: 'Marcado',
            icon: 'mdi-calendar-check',
            color: '#84b6f4',
          },
          {
            value: 3,
            text: 'Faltou',
            icon: 'mdi-close-octagon-outline',
            color: '#ff6961',
          },
          {
            value: 4,
            text: 'Finalizado',
            icon: 'mdi-check-circle-outline',
            color: '#c5c6c8',
          },
          {
            value: 5,
            text: 'Cancelado',
            icon: 'mdi-cancel',
            color: '#ffe180',
          },
        ];

        if (!this.opcoesSituacaoAgendamento.find((x) => x.value === this.agendamento.situacaoAgendamento)) {
          this.agendamento.situacaoAgendamento = SituacaoAgendamento.Marcado;
        }
      }
    },
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    exibirTriagemRetorno: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    cpfValido() {
      return this.$rules.cpf.validate(this.cpfPaciente);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    titulo() {
      if (this.editarAgendamento) {
        return 'Editar Agendamento';
      }
      if (this.preConsultaPaciente && this.agendamento.tipoAgendamento === TipoAgendamento.Consulta) {
        return 'Pré Consulta';
      }
      if (this.agendamentoRetorno) {
        return 'Criar Agendamento Retorno';
      }

      return 'Criar Agendamento';
    },
    verificaEdicao() {
      return this.editarAgendamento;
    },
  },
  async created() {
    await this.getPacientes();
  },
  methods: {
    // #region <Funções de abertura e fechamento do Modal de Agendamento>
    async abrirModal(agenda: Agenda, data?: string, idPacienteRetorno?: number, horario?: string) {
      try {
        this.agenda = agenda;
        this.idAgenda = agenda.id;
        const horariosReservados: IHorarioReservado[] = await this.verificarSeExisteConflitoDeAgenda(data, horario);

        if (horariosReservados && horariosReservados.length > 0) {
          const feriados: IHorarioReservado[] = horariosReservados.filter((item: IHorarioReservado) => item.tipo === 'FERIADO');

          if (feriados && feriados.length > 0) {
            MensagemMixin(8000, 'error', `Este dia está registrado como feriado pela agenda '${feriados[0].nomeAgenda}' e não permite agendamentos!`);
            this.fecharModal();
            return;
          }

          const bloqueios: IHorarioReservado[] = horariosReservados.filter((item: IHorarioReservado) => item.tipo === 'BLOQUEIO');

          if (bloqueios && bloqueios.length > 0) {
            MensagemMixin(8000, 'error', `Este dia foi bloqueado pela agenda '${bloqueios[0].nomeAgenda}' e não permite agendamentos!`);
            this.fecharModal();
            return;
          }

          const agendamento: IHorarioReservado[] = horariosReservados.filter((item: IHorarioReservado) => item.tipo === 'AGENDAMENTO');

          if (agendamento && agendamento.length > 0) {
            MensagemMixin(8000, 'info', `O horário selecionado possui reservas pela agenda: '${agendamento[0].nomeAgenda}'.`);
          }
        }

        const dataEstaBloqueada = data && (await this.dataEstaBloqueada(data));

        if (dataEstaBloqueada) {
          return;
        }

        if (!data) {
          data = await this.buscarProximaDataDisponivel(this.idAgenda);

          if (data === undefined) {
            return;
          }
        }

        this.agendamento = new Agendamento();

        if (idPacienteRetorno) {
          this.agendamentoRetorno = true;
          this.agendamento.agendamentoConsulta.retorno = true;
          this.agendamento.agendamentoConsulta.pacienteId = idPacienteRetorno;
          this.pacienteService.Get(idPacienteRetorno).then((res) => {
            this.cpfPaciente = res.data.cpf;
            this.pacienteSelecionado = (res.data.usarNomeSocialComoPrincipal) ? res.data.nomeSocial : `${res.data.nome} ${res.data.sobrenome}`;
            this.agendamento.agendamentoConsulta.telefone = res.data.contato.telefonePrincipal;
          });
        }

        this.modalAberto = true;
        this.carregando = true;
        this.desabilitarBotaoTriagem = false;

        await Promise.all([
          this.preencherTipoConsulta(this.idAgenda),
          this.preencherConvenios(),
          this.buscarHorarios(this.idAgenda, DateHelper.formatarParaAnosMesDiaISO(data)),
        ]);

        this.agendamento.horaAgendamento = horario;
        this.agendamento.situacaoAgendamento = SituacaoAgendamento.Marcado;
        this.agendamento.agendaId = this.idAgenda;
        this.agendamento.dataAgendamento = DateHelper.formataParaDiaMesAno(data);
        this.date = DateHelper.formataParaAnoMesDia(data);
        this.preencherDuracaoAgendamento();
        this.carregando = false;
      } catch (error) {
        AlertaSimplesErro('Aviso:', error);
        this.fecharModal();
      }
    },
    async abrirModalParaEdicao(idAgendamento: number, agenda: Agenda, agendamentoRetorno?: Agendamento) {
      try {
        this.agenda = agenda;
        this.idAgenda = agenda.id;
        await Promise.all([
          this.preencherTipoConsulta(this.idAgenda),
          this.preencherConvenios(),
        ]);
        this.editarAgendamento = true;
        this.agendamento = new Agendamento();
        this.carregando = true;
        this.modalAberto = true;

        let data;
        let agendamento;
        let agendamentoConsulta;
        let dataAgendamento;

        if (!agendamentoRetorno) {
          await this.agendamentoService
            .Get(idAgendamento)
            .then((res) => {
              data = res.data;
            });
          agendamento = data;
          agendamentoConsulta = agendamento.agendamentoConsulta;
          if (agendamentoConsulta) {
            this.pacienteSelecionado = (agendamentoConsulta.paciente.usarNomeSocialComoPrincipal) ? agendamentoConsulta.paciente.nomeSocial : agendamentoConsulta.paciente.nomeCompleto;
          }
          dataAgendamento = agendamento.dataAgendamento;
        } else {
          data = agendamentoRetorno;
          agendamento = agendamentoRetorno;
          agendamentoConsulta = agendamentoRetorno.agendamentoConsulta;
          this.pacienteSelecionado = (agendamentoConsulta.paciente.usarNomeSocialComoPrincipal) ? agendamentoConsulta.paciente.nomeSocial : agendamentoConsulta.paciente.nomeCompleto;
          dataAgendamento = agendamentoRetorno.dataAgendamento;
        }

        if (agendamentoConsulta) {
          await this.triagemService.GetTriagemPorAgendamentoId(idAgendamento).then((res) => {
            this.desabilitarBotaoTriagem = true;
          }).catch((error) => {
            this.desabilitarBotaoTriagem = false;
          });
        } else {
          this.desabilitarBotaoTriagem = true;
        }

        await this.buscarHorarios(
          this.idAgenda,
          DateHelper.formatarParaAnosMesDiaISO(dataAgendamento),
        );

        this.horarios.push({
          text: agendamento.horaAgendamento,
          value: agendamento.horaAgendamento,
        });

        // eslint-disable-next-line no-unused-expressions
        agendamentoConsulta?.convenioId && (await this.preencherPlanos(agendamentoConsulta.convenioId));

        this.buscarPeloPaciente = agendamentoConsulta?.paciente;
        this.cpfPaciente = agendamentoConsulta?.paciente.cpf;
        this.agendamento.tipoAgendamento = agendamento.tipoAgendamento;
        setTimeout(() => { }, 1);
        this.agendamento = {
          ...agendamento,
          agendamentoConsulta: agendamento.tipoAgendamento === TipoAgendamento.Compromisso ? new AgendamentoConsulta() : { ...agendamentoConsulta },
          agendamentoCompromisso: agendamento.tipoAgendamento === TipoAgendamento.Consulta ? new AgendamentoCompromisso() : { ...agendamento.agendamentoCompromisso },
        };

        this.date = DateHelper.formataParaAnoMesDia(dataAgendamento);
        this.agendamento.dataAgendamento = DateHelper.formataParaDiaMesAno(dataAgendamento);
        this.carregando = false;
      } catch (error) {
        AlertaSimplesErro('Aviso:', error);
        this.fecharModal();
      }
    },
    fecharModal() {
      this.horarios = [];
      this.agendamento = new Agendamento();
      this.preConsultaPaciente = false;
      this.modalAberto = false;
      this.carregando = false;
      this.editarAgendamento = false;
      this.cpfPaciente = '';
      this.pacienteSelecionado = '';
      this.idPacienteSelecionado = 0;
      this.limparCamposPreConsulta();
    },
    // #endregion
    // #region <Funções Paciente>
    async carregarDadosPaciente(pacienteId: string) {
      try {
        const response = await this.pacienteService.Get(pacienteId);
        const paciente = response.data;

        if (paciente) {
          this.agendamento.agendamentoConsulta.telefone = paciente.contato.telefonePrincipal;
          this.agendamento.agendamentoConsulta.email = paciente.contato.emailPrincipal;
          this.cpfPaciente = paciente.cpf;
          this.idPacienteSelecionado = paciente.id;
        }
      } catch (error) {
        AlertaSimplesErro('Erro ao carregar dados do paciente:', error);
      }
    },
    async atualizarDadosPaciente() {
      if (this.agendamento.agendamentoConsulta.pacienteId !== 0) {
        try {
          await this.carregarDadosPaciente(this.agendamento.agendamentoConsulta.pacienteId);
        } catch (error) {
          AlertaSimplesErro('Erro ao atualizar dados do paciente:', error);
        }
      }
    },
    alteraVariavelNovoPaciente() {
      this.preConsultaPaciente = !this.preConsultaPaciente;

      if (this.preConsultaPaciente) {
        this.limparCamposPreConsulta();
      }
    },
    async getPacientes() {
      this.carregando = true;
      await this.pacienteService
        .BuscarPaciente()
        .then(
          (res) => {
            this.pacientes = res.data;
            (this as any).pacientes.forEach((paciente) => {
              paciente.idade = DateHelper.calculaIdadePorExtenso(paciente.dataNascimento);
              paciente.dataNascimento = DateHelper.formataParaDiaMesAno(paciente.dataNascimento);
            });
          },
          (err) => {
            AlertaSimplesErro('Aviso:', err);
          },
        );
      this.carregando = false;
    },
    // #endregion
    // #region <Funções do componente de Situação do Agendamento>
    corDaSituacaoAgendamento(situacaoAgendamento: SituacaoAgendamento) {
      return this.opcoesSituacaoAgendamento.find((x) => x.value === situacaoAgendamento)!.color;
    },
    iconeDaSituacaoAgendamento(situacaoAgendamento: SituacaoAgendamento) {
      return this.opcoesSituacaoAgendamento.find((x) => x.value === situacaoAgendamento)!.icon;
    },
    textDaSituacaoAgendamento(situacaoAgendamento: SituacaoAgendamento) {
      return this.opcoesSituacaoAgendamento.find((x) => x.value === situacaoAgendamento)!.text;
    },
    // #endregion
    async dataEstaBloqueada(dataParaVerificar: string): Promise<boolean> {
      try {
        const dataParaVerificarTipada: Date = new Date(`${dataParaVerificar}T00:00:00`);

        const bloqueioEncontrado = this.datasBloqueadas.find(
          (periodo) => dataParaVerificarTipada >= new Date(periodo.dataInicio)
            && dataParaVerificarTipada <= new Date(periodo.dataFim),
        );

        const feriadoEncontrado = this.feriados.find(
          (feriado) => new Date(feriado.data).getDate() === dataParaVerificarTipada.getDate()
            && new Date(feriado.data).getMonth() === dataParaVerificarTipada.getMonth(),
        );

        const diaSemana = dataParaVerificarTipada.getDay();

        if (!this.agenda.listaHorarios.find((x) => x.diaSemana === diaSemana)) {
          MensagemMixin(8000, 'error', 'Este dia não possui horário cadastrado!');
          return true;
        }

        if (!this.editarAgendamento && DateHelper.dataMenorQueHoje(dataParaVerificar)) {
          MensagemMixin(8000, 'error', 'Não é possível cadastrar um agendamento com data passada!');
          return true;
        }
        if (bloqueioEncontrado) {
          MensagemMixin(8000, 'error', 'Existe um bloqueio para esta data!');
          return true;
        }
        if (feriadoEncontrado) {
          MensagemMixin(8000, 'error', 'O feriado está marcado para esta data!');
          return true;
        }

        return false;
      } catch (error) {
        console.error(error);
      }
    },
    desabilitarDatas(dataParaVerificar: string): boolean {
      const data: Date = new Date(`${dataParaVerificar}T00:00:00`);

      if (DateHelper.dataMenorQueHoje(dataParaVerificar)) {
        return false;
      }

      const diaSemana = data.getDay();

      if (!this.agenda.listaHorarios.find((x) => x.diaSemana === diaSemana)) {
        return false;
      }

      if (this.datasBloqueadas.some((periodo) => data >= new Date(periodo.dataInicio)
        && data <= new Date(periodo.dataFim))) {
        return false;
      }

      if (this.feriados.some((feriado) => feriado.data === `${dataParaVerificar}T00:00:00`)) {
        return false;
      }

      return true;
    },
    parseDate(date: string) {
      if (!date) {
        return '';
      }

      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    async buscarProximaDataDisponivel(idAgenda: number) {
      try {
        const resposta = await this.agendamentoService.BuscarProximaDataDisponivel(idAgenda);
        return resposta.data;
      } catch (error) {
        AlertaSimplesErro('Aviso:', error);
        return null;
      }
    },
    // #region <Funções Pré Consulta>
    limparCamposPreConsulta() {
      this.agendamento.agendamentoConsulta.telefone = '';
      this.agendamento.agendamentoConsulta.email = '';
      this.agendamento.agendamentoConsulta.pacienteId = '';
      this.agendamento.agendamentoConsulta.convenioId = null;
      this.agendamento.agendamentoConsulta.planoId = null;
      this.cpfPaciente = '';
      this.limparDadosPacienteSessaoArmazenamento();
    },
    // #endregion
    async preencherConvenios() {
      try {
        const resposta = await this.convenioService.getAllSelect();
        this.options_convenio = resposta.data;
      } catch (error) {
        console.error('Erro ao preencher convênios:', error);
      }
    },
    async preencherPlanos(convenioId: number) {
      this.options_plano = [];
      const resposta = await this.planoService.getAllSelect(convenioId);
      this.options_plano = resposta.data;
    },
    mudarPlanos() {
      this.agendamento.agendamentoConsulta.planoId = undefined;
      this.preencherPlanos(this.agendamento.agendamentoConsulta.convenioId);
    },
    async preencherTipoConsulta(idAgenda: number) {
      try {
        const resposta = await this.tipoConsultaService.getAllSelect(idAgenda);
        this.options_consulta = resposta.data;
      } catch (error) {
        if (error.response.status) {
          console.error(error.response.status);
          console.error(error.response.data);
          return;
        }

        console.error(error);
      }
    },
    mudarTipoAgendamento() {
      if (this.agendamento.tipoAgendamento === TipoAgendamento.Compromisso) {
        this.limparCamposPreConsulta();
        this.$set(this.agendamento, 'agendamentoConsulta', new AgendamentoConsulta());
        this.agendamento.agendamentoConsulta.tipoConsultaId = undefined;
      } else {
        this.$set(this.agendamento, 'agendamentoCompromisso', new AgendamentoCompromisso());
      }

      this.carregarDadosDoPacienteSelecionado();
    },
    async buscarHorarios(idAgenda: number, data: string): Promise<boolean> {
      this.horarios = [];
      let possuiHorarios = false;

      try {
        const resposta = await this.agendamentoService.GetAgendamentoHorarios(idAgenda, DateHelper.formatarParaAnosMesDiaISO(data));
        this.horariosAgenda = resposta.data;
        resposta.data.forEach((listaHorarios: { horarios: ConcatArray<never> }) => {
          this.horarios = this.horarios.concat(listaHorarios.horarios);
        });

        if (this.horariosAgenda.length > 0) {
          possuiHorarios = true;
          this.agendamento.horaAgendamento = this.horariosAgenda[0].horarios[0].value;
          this.agendamento.duracaoAgendamento = this.horariosAgenda[0].duracaoAgendamento;
        }

        return possuiHorarios;
      } catch (error) {
        possuiHorarios = false;
        this.agendamento.horaAgendamento = '';
        this.agendamento.duracaoAgendamento = '';
        AlertaSimplesErro('Aviso:', error);
        return possuiHorarios;
      }
    },
    preencherDuracaoAgendamento() {
      const horarioAgendaEncontrado = this.horariosAgenda.find((listaHorarios: { horarios: { value: string; }[]; }) => listaHorarios.horarios
        .some((hora: { value: string }) => hora.value === this.agendamento.horaAgendamento));

      if (horarioAgendaEncontrado) {
        this.agendamento.duracaoAgendamento = horarioAgendaEncontrado.duracaoAgendamento;
      }
    },
    async salvar(): Promise<boolean> {
      const dateBackup = this.date;

      if (this.agendamento.tipoAgendamento === TipoAgendamento.Consulta && !this.preConsultaPaciente) {
        if (this.agendamento.agendamentoConsulta && !this.agendamento.agendamentoConsulta.pacienteId) {
          AlertaSimples('Atenção', 'Selecione um paciente para continuar.', 'warning');
          return false;
        }
      }

      const formularioValido = await (this.$refs.observer as any).validate();

      if (!formularioValido) {
        return false;
      }

      try {
        if (await this.dataEstaBloqueada(DateHelper.formataParaAnoMesDia(this.date))) {
          return false;
        }

        this.agendamento.dataAgendamento = DateHelper.formataParaAnoMesDia(this.date);
        this.carregando = true;

        // Se for um agendamento de retorno (sem salvar diretamente)
        if (this.agendamentoRetorno) {
          this.$emit('retorno', this.agendamento);
          this.fecharModal();
          return true;
        }

        // Ajusta os objetos de consulta/compromisso conforme o tipo
        if (this.agendamento.tipoAgendamento === TipoAgendamento.Consulta) {
          if (!this.preConsultaPaciente) {
            this.agendamento.agendamentoConsulta.paciente = undefined;
          }

          this.agendamento.agendamentoCompromisso = undefined;
        } else if (this.agendamento.tipoAgendamento === TipoAgendamento.Compromisso) {
          this.agendamento.agendamentoConsulta = undefined;
        }

        if (this.preConsultaPaciente) {
          this.agendamento.agendamentoConsulta.pacienteId = 0;
          this.agendamento.agendamentoConsulta.paciente.telefonePrincipal = this.agendamento.agendamentoConsulta.telefone;
          this.agendamento.agendamentoConsulta.paciente.emailPrincipal = this.agendamento.agendamentoConsulta.email;
          this.agendamento.agendamentoConsulta.paciente.cpf = this.cpfPaciente;
        } else if (this.agendamento.tipoAgendamento === TipoAgendamento.Compromisso) {
          this.agendamento.agendamentoConsulta = undefined;
        }

        // Se é pré-consulta, cria o objeto paciente “inline”
        if (this.preConsultaPaciente) {
          this.agendamento.agendamentoConsulta.pacienteId = 0;
          this.agendamento.agendamentoConsulta.paciente.telefonePrincipal = this.agendamento.agendamentoConsulta.telefone;
          this.agendamento.agendamentoConsulta.paciente.emailPrincipal = this.agendamento.agendamentoConsulta.email;
          this.agendamento.agendamentoConsulta.paciente.cpf = this.cpfPaciente;
        }

        // Salva ou edita o agendamento
        let resposta: { data: { tipoAgendamento: TipoAgendamento; agendamentoConsulta: { pacienteId: number }; id: number } };

        if (this.editarAgendamento) {
          console.error('CAIU AQUI');
          resposta = await this.agendamentoService.Put(this.agendamento.id, this.agendamento);
        } else {
          resposta = await this.agendamentoService.Post(this.agendamento);
        }

        // Se for tipo Consulta, atualiza os IDs
        if (resposta.data.tipoAgendamento === TipoAgendamento.Consulta) {
          this.idPaciente = resposta.data.agendamentoConsulta.pacienteId;
          this.idAgendamento = resposta.data.id;
        }

        const mensagem = this.editarAgendamento
          ? 'Agendamento editado com sucesso.'
          : 'Agendamento cadastrado com sucesso.';
        MensagemMixin(3000, 'success', mensagem);
        this.fecharModal();
        this.$emit('atualizaCalendario');
        return true;
      } catch (error) {
        // Trata erros
        this.date = '';
        // Ajuste de data (caso seja necessário restaurar)
        // eslint-disable-next-line
        await new Promise((resolve) => setTimeout(resolve, 1));
        this.date = dateBackup;
        AlertaSimplesErro('Aviso:', error);
        this.carregando = false;
        return false;
      }
    },
    async realizarTriagemParaForm() {
      try {
        const sucessoAoSalvar = await this.salvar();

        if (sucessoAoSalvar) {
          this.modalTriagens = true;

          if (this.$refs.modalTriagem) {
            await this.$refs.modalTriagem.carregarTriagemNoAgendamento(this.idAgendamento, this.idPaciente);
          } else {
            console.error('Referência ao modal de triagem não encontrada.');
          }
        }
      } catch (error) {
        console.error('Erro ao realizar triagem:', error);
      }
    },
    fecharModalTriagem() {
      this.modalTriagens = false;
    },
    validarDataNascimentoParaDesabilitarTriagem() {
      const { dataNascimento } = this.agendamento.agendamentoConsulta.paciente;

      if (dataNascimento) {
        this.dataNascimentoValido = true;
      } else {
        this.dataNascimentoValido = false;
      }
    },
    desabilitaModulosComplementares() {
      const sessaoUsuario = decode(getToken());
      const temAcessoTriagem = sessaoUsuario.permissions.includes(PermissoesPadrao.AcessoTriagem);
      const temTriagemCreate = sessaoUsuario.permissions.includes(PermissoesPadrao.TriagemCreate);

      if (temAcessoTriagem && temTriagemCreate) {
        return false;
      }

      return true;
    },
    changePaciente(paciente: any) {
      this.pacienteSelecionado = (paciente[0].usarNomeSocialComoPrincipal) ? paciente[0].nomeExibirSocial : paciente[0].nomeCompleto;
      this.idPacienteSelecionado = paciente[0].id;
      this.agendamento.agendamentoConsulta.pacienteId = this.idPacienteSelecionado;
      this.idPacienteRetorno = this.idPacienteSelecionado;
      this.atualizarDadosPaciente();
    },
    async obterTodosOsHorariosReservadosDesdeHoje(): Promise<IHorarioReservado[]> {
      try {
        const todosHorariosReservados = await this.agendaService.ObterTodosOsHorariosReservadosDesdeHoje();

        if (todosHorariosReservados && todosHorariosReservados.data) {
          return Array.isArray(todosHorariosReservados.data) ? todosHorariosReservados.data : [];
        }

        return [];
      } catch (error) {
        console.error('Erro ao buscar bloqueios e feriados:', error);
        return [];
      }
    },
    async verificarSeExisteConflitoDeAgenda(data: string, horario: string): Promise<any[]> {
      const horariosReservados = await this.obterTodosOsHorariosReservadosDesdeHoje();

      if (!Array.isArray(horariosReservados) || horariosReservados.length === 0) {
        // Nenhum horário reservado encontrado.
        return [];
      }

      const dataHorarioVerificada = new Date(`${data}T${horario}`);
      const itensConflitantes = horariosReservados.filter((item) => {
        const inicio = new Date(item.dataInicio);
        const fim = new Date(item.dataFim);
        return dataHorarioVerificada >= inicio && dataHorarioVerificada <= fim;
      });

      return itensConflitantes;
    },
    async carregarDadosDoPacienteSelecionado() {
      const pacienteCarregadoNaSessaoAtual = this.verificarSeExistemDadosNaSessaoArmazenamento();

      if (!pacienteCarregadoNaSessaoAtual && this.idPacienteSelecionado === 0) {
        return;
      }

      if (!pacienteCarregadoNaSessaoAtual) {
        if (this.idPacienteSelecionado > 0) {
          this.carregando = true;
          const paciente = await this.pacienteService.ObterDadosDoPaciente(this.idPacienteSelecionado);

          if (paciente.data) {
            this.$nextTick(() => {
              this.persistirDadosPacienteSessaoArmazenamento(paciente.data);
              this.recarregarDadosPaciente(paciente.data);
              this.carregando = false;
            });
            return;
          }
        }

        this.carregando = false;
        return;
      }

      this.carregando = true;
      this.$nextTick(() => {
        const paciente = this.obterDadosPacienteSessaoArmazenamento();
        this.recarregarDadosPaciente(paciente);
        this.carregando = false;
      });
    },
    recarregarDadosPaciente(paciente: Pacientes) {
      this.agendamento.agendamentoConsulta = {
        ...this.agendamento.agendamentoConsulta,
        paciente,
        email: paciente.contato.emailPrincipal,
        telefone: paciente.contato.telefonePrincipal,
        pacienteId: paciente.id,
      };
      this.cpfPaciente = paciente.cpf;
      this.pacienteSelecionado = `${paciente.nome} ${paciente.sobrenome}`;
    },
    persistirDadosPacienteSessaoArmazenamento(dadosPaciente: Pacientes) {
      sessionStorage.setItem(this.SESSAO_PACIENTE_ARMAZENAMENTO, JSON.stringify(dadosPaciente));
    },
    obterDadosPacienteSessaoArmazenamento() {
      return JSON.parse(sessionStorage.getItem(this.SESSAO_PACIENTE_ARMAZENAMENTO) || '{}');
    },
    verificarSeExistemDadosNaSessaoArmazenamento() {
      return Object.keys(this.obterDadosPacienteSessaoArmazenamento()).length > 0;
    },
    limparDadosPacienteSessaoArmazenamento() {
      sessionStorage.removeItem(this.SESSAO_PACIENTE_ARMAZENAMENTO);
    },
  },
});
</script>
