<template>
  <v-card flat class="mt-2 mb-2">
    <v-card-title>Endereço</v-card-title>
    <v-card-text>
      <v-row class="form">
        <v-col cols="12" md="3">
          <soi-input
            :name="'CEP'"
            :label="'CEP'"
            v-model="endereco.cep"
            :placeholder="'00000-000'"
            :mask="masks.cep"
            :disabled="disabled"
            v-on:input="getCep()"
          ></soi-input>
        </v-col>
        <v-col cols="12" md="5">
          <soi-input
            :name="'Cidade'"
            :label="'Cidade'"
            v-model="endereco.cidade"
            :disabled="disabled"
            :placeholder="'Cidade'"
          ></soi-input>
        </v-col>
        <v-col cols="12" md="4">
          <soi-select
            :label="'Estado'"
            :items="enumEstado"
            v-model="endereco.estado"
            :disabled="disabled"
          >
          </soi-select>
        </v-col>
        <v-col cols="12" md="3">
          <soi-select
            :name="'Pais'"
            :label="'País'"
            :items="enumPais"
            v-model="endereco.pais"
            :disabled="disabled"
          >
          </soi-select>
        </v-col>
        <v-col cols="12" md="3">
          <soi-input
            :name="'Bairro'"
            :label="'Bairro'"
            v-model="endereco.bairro"
            :disabled="disabled"
            :placeholder="'Bairro'"
          >
          </soi-input>
        </v-col>
        <v-col cols="12" md="6">
          <soi-input
            :name="'Rua'"
            :label="'Rua'"
            v-model="endereco.logradouro"
            :disabled="disabled"
            :placeholder="'Rua'"
          ></soi-input>
        </v-col>
        <v-col cols="12" md="3">
          <soi-input
            :name="'Número'"
            :label="'Número'"
            v-model="endereco.numero"
            :disabled="disabled"
            :placeholder="'Número'"
          >
          </soi-input>
        </v-col>
        <v-col cols="12" md="3">
          <soi-select
            :name="'Tipo'"
            :label="'Tipo'"
            :items="enumTipoEndereco"
            v-model="endereco.tipoEndereco"
            :disabled="disabled"
          ></soi-select>
        </v-col>
        <v-col cols="12" md="6">
          <soi-input
            :name="'Complemento'"
            :label="'Complemento'"
            v-model="endereco.complemento"
            :placeholder="'Complemento'"
            :disabled="disabled"
          ></soi-input>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import MaskHelper from '@/helpers/MaskHelper';
import {
  Estado, SiglaUf, Pais, TipoEndereco,
} from '@/models/Enums';
import Endereco from '@/models/objetosDeValor/Endereco';
import { EnderecoService } from '@/services/EnderecoService';
import Vue from 'vue';

export default Vue.component('soi-endereco', {
  props: {
    value: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      endereco: new Endereco(),
      service: new EnderecoService(),
      enumEstado: Estado,
      enumSiglaUf: SiglaUf,
      enumPais: Pais,
      enumTipoEndereco: TipoEndereco,
      masks: MaskHelper,
    };
  },
  methods: {
    async getCep() {
      if (this.endereco.cep.length === 9) {
        await this.service.get(this.endereco.cep).then((response) => {
          this.endereco.logradouro = response.logradouro;
          this.endereco.bairro = response.bairro;
          this.endereco.cidade = response.cidade;
          this.endereco.estado = response.estado;
          this.endereco.pais = response.pais;
        });
      }
    },
  },
  watch: {
    value() {
      this.endereco = this.value;
    },
    endereco() {
      this.$emit('input', this.endereco);
    },
  },
});
</script>
