import JwtHelper from '@/helpers/JwtHelper';
import AuthUser, { IAuthUserModel } from '../models/Auth/AuthUserModel';
import Cookies from 'js-cookie';
import { parsePermissions } from './permission';

export function setToken(token: string) {
  Cookies.set('@medSystemCloud:token', token);
}

export function getToken(): string {
  return Cookies.get('@medSystemCloud:token') || '';
}

export function removeToken() {
  Cookies.remove('@medSystemCloud:token');
}

export function setModoNoturno(modoNoturno: string) {
  Cookies.set('@medSystemCloud:modoNoturno', modoNoturno);
}

export function getModoNoturno(): boolean {
  return Cookies.get('@medSystemCloud:modoNoturno') === 'true' || false;
}

export function removeModoNoturno() {
  Cookies.remove('@medSystemCloud:modoNoturno');
}

export function decode(token: string): IAuthUserModel {
  if (token.length) {
    const decoded = JwtHelper.decodeToken(token);

    const permissions: Array<string> = parsePermissions(decoded.permissions);

    const data: IAuthUserModel = {
      id: decoded.userid,
      name: decoded.name,
      sobrenome: decoded.sobrenome,
      userName: decoded.username,
      profilePicture: decoded.profilePicture,
      role: decoded.role,
      permissions,
      empresaId: decoded.empresaid,
      tenantId: decoded.tenantid,
      razaoSocial: decoded.razaoSocial,
      versaoTeste: decoded.versaoTeste,
      dataInadimplencia: decoded.dataInadimplencia,

    };
    return data;
  }


  return new AuthUser();
}

export function getUserPermissions(token: string): string[] {
  const decodedToken: any = JwtHelper.decodeToken(token);
  const permissions: Array<string> = parsePermissions(decodedToken.permissions);

  return permissions;
}





export interface IDecodedConfirmationToken {
[x: string]: any;
    tenantInfo: number;
    agendaId: number;
    agendamentoId: number;


}

export function decodeConfirmationToken(token: string): IDecodedConfirmationToken {
  const decoded: IDecodedConfirmationToken = JwtHelper.decodeToken(token);
  return decoded;
}
