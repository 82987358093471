<template>
    <div class="text-center">
        <v-card flat class="mt-2 mb-2">
            <v-card-title>Paciente</v-card-title>
            <v-card-text>
                <v-row>
                    <!-- <v-col cols="12" md="2" sm="12" xs="1">
                        <soi-input
                            :name="'ID'"
                            :label="'Id'"
                            v-model="paciente.id"
                            :placeholder="'Id do Paciente'"
                            :disabled="true"
                        ></soi-input>
                    </v-col> -->
                    <v-col cols="12" md="6" sm="12" xs="12">
                        <soi-input :name="'Nome'" :label="'Nome *'" v-model="paciente.nome" :rules="{ required: true }"
                            :placeholder="'Nome'" :disabled="disabled"></soi-input>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" xs="12">
                        <soi-input :name="'Sobrenome'" :label="'Sobrenome *'" v-model="paciente.sobrenome"
                            :rules="{ required: true }" :placeholder="'Sobrenome'" :disabled="disabled"></soi-input>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" xs="12">
                        <soi-input :name="'Nome Social'" :label="'Nome Social'" v-model="paciente.nomeSocial"
                            :placeholder="'Nome Social'" :disabled="disabled"></soi-input>
                    </v-col>
                    <v-col class="mb-5" cols="12" md="6" sm="12" xs="12">
                        <v-checkbox :disabled="paciente.nomeSocial.length < 2" @input="limparCheckboxNomeSocial"
                            label="Utilizar nome social como principal"
                            v-model="paciente.usarNomeSocialComoPrincipal" />
                    </v-col>
                    <v-col cols="12" md="3" sm="12" xs="12">
                        <soi-input :name="'CPF'" :label="cpfObrigatorio ? 'Número do CPF *' : 'Número do CPF'"
                            v-model="paciente.cpf" :disabled="disabled" :rules="cpfRules"
                            :placeholder="'000.000.000-00'" :mask="masks.cpf">
                        </soi-input>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" xs="12">
                        <soi-input :name="'RG'" :label="'Número do RG'" v-model="paciente.rg"
                            :placeholder="'MG-8.321.654'" :disabled="disabled"></soi-input>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" xs="12">
                        <soi-input :name="'Data de Nascimento'" :label="'Data de Nascimento *'" :disabled="disabled"
                            :rules="{ required: true }" v-model="paciente.dataNascimento" type="date"></soi-input>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" xs="12">
                        <soi-input :name="'Idade'" :label="'Idade'" v-model="idade" disabled :placeholder="'Idade'">
                        </soi-input>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" xs="12">
                        <soi-input :name="'Nome do pai'" :label="'Nome do pai'" v-model="paciente.nomePai"
                            :placeholder="'Nome do pai'" :disabled="disabled"></soi-input>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" xs="12">
                        <soi-input :name="'Nome da mãe'" :label="'Nome da mãe'" v-model="paciente.nomeMae"
                            :placeholder="'Nome da mãe'" :disabled="disabled"></soi-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="3" sm="12" xs="12">
                        <soi-select :label="'Sexo'" :items="enumSexo" v-model="paciente.sexo"
                            :disabled="disabled"></soi-select>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" xs="12">
                        <soi-select :label="'Estado Civil'" :items="enumEstadoCivil" v-model="paciente.estadoCivil"
                            :disabled="disabled"></soi-select>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" xs="12">
                        <soi-select :label="'Escolaridade'" :items="enumEscolaridade" v-model="paciente.escolaridade"
                            :disabled="disabled"></soi-select>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" xs="12">
                        <soi-select :label="'Raça'" :items="enumRaca" v-model="paciente.raca"
                            :disabled="disabled"></soi-select>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import Vue from 'vue';
import Pacientes from '../../models/pacientes/Pacientes';
import {
  Sexo, Escolaridade, EstadoCivil, Raca,
} from '../../models/Enums';
import MaskHelper from '../../helpers/MaskHelper';
import DateHelper from '../../helpers/DateHelper';

export default Vue.component('soi-dados-padrao-paciente', {
  props: {
    value: {
      type: Pacientes,
      default: () => new Pacientes(),
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    cpfObrigatorio: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    cpfRules() {
      return {
        required: this.cpfObrigatorio,
        min: 14,
        cpf: 'cpf',
      };
    },
  },
  data() {
    return {
      enumSexo: Sexo,
      enumEscolaridade: Escolaridade,
      enumEstadoCivil: EstadoCivil,
      enumRaca: Raca,
      paciente: new Pacientes(),
      masks: MaskHelper,
      idade: '',
    };
  },

  methods: {
    limparCheckboxNomeSocial() {
      if (this.paciente.nomeSocial.length < 2) {
        this.paciente.usarNomeSocialComoPrincipal = false;
      }
    },

  },
  watch: {
    value() {
      this.paciente = this.value;
    },
    paciente() {
      this.$emit('input', this.paciente);
    },
    'paciente.dataNascimento': {
      immediate: true,
      handler(novaDataNascimento) {
        if (novaDataNascimento) {
          this.idade = DateHelper.calculaIdadePorExtenso(novaDataNascimento);
        } else {
          this.idade = '';
        }
      },
    },
    'paciente.nomeSocial': {
      immediate: true,
      handler(novoNomeSocial) {
        if (novoNomeSocial.length < 2) {
          this.paciente.usarNomeSocialComoPrincipal = false;
        }
      },
    },
  },
  mounted() {
    this.limparCheckboxNomeSocial();
    this.paciente = this.value || new Pacientes();
    this.idade = DateHelper.calculaIdadePorExtenso(this.paciente.dataNascimento);
  },
});
</script>
