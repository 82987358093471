<template>
    <router-view :key="$route.fullPath" />
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'App',
});
</script>

<style>
@font-face {
    font-family: 'New SEC APP';
    src: url('./assets/fonts/SecApp.ttf') format('truetype');
}

html {
    font-family: 'New SEC APP';
    background-color: rgba(138, 147, 172, 0.2) ;
}

body {
    font-family: 'New SEC APP';
}

.swal2-popup {
    font-family: 'New SEC APP';
}

.v-card {
    border-radius: 0.5rem !important;
    margin: 1.875rem;
}
</style>
