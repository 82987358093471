import http from './ApiConfig';
import { Service } from './Service';

export class PlanoService extends Service {
  constructor() {
    super('planos');
  }

  public getAllSelect(convenioId: number) {
    return http.get(`${this._nomeControle}/lista-planos/?convenioId=${convenioId}`, this.GetHeader());
  }
}
